<template lang="pug">
Slider(v-if="!featured" class="h-100vh color-white font-size-1.5 $px-2rem $py-3rem d:$px-3rem" :slides-shown="1" loop :viewportIndex.sync="viewportIndex" :current.sync="currentPostIndex")
	template(v-slot:default="{slide, slides}")
		article(
			v-bind="slide" 
			class="bg-color-$primary p-$py-$px !first:mt-1.5 child:m-0 bg-center/cover flexbox-column-unset-flex-start pr-3"
			:style="{...slide.style, backgroundImage: `url(${post.featured_image})`, '--primary': post.primary_color}"
			v-for="(post, i) in posts"
			:key="post.id"
			v-fade-image
		)
			div(class="h-1em flexbox-center-center")
				img(v-if="post.logo" :src="post.logo" class="max-w-3em ml-5em max-h-3em")
			h2(class="font-size-2.5em font-weight-900" v-parallax="{ slide: slides(i), strength: .2 }") Case Study: <br>
				span(v-html="post.title")
			router-link(
				:to="{name: 'Post', params: { category: 'case-study', slug: post.slug}}" 
				@click.native="$root.setCircleColor(post.primary_color)"
				class="button $color-white $hover-color-$primary"  
				v-parallax="{ slide: slides(i), strength: .6 }"
			) See Case Study
		div(class="abs p-$py-$px flexbox-unset-center !first:ml-1")
			LogoBring
			transition(name="fade")
				CrossIcon(class="$transition-duration-300ms" :style="{transform: `rotate(${viewportIndex * -360}deg)`}" v-show="currentPost.logo")
	template(v-slot:pagination="scope")
		SliderPagination(v-bind="scope" class="important:r-initial important:m-0 important:p-$py-$px")
	template(v-slot:navigation="scope")
		SliderNext(v-bind="scope" class="font-size-3")
		SliderPrevious(v-bind="scope" class="font-size-3" v-if="$desktop")
div(v-else-if="posts && posts.length" class="h-100vh color-white font-size-1.5 $px-2rem $py-3rem d:$px-3rem relative")
	article(
		class="bg-color-$primary p-$py-$px !first:mt-1.5 child:m-0 bg-center/cover flexbox-column-unset-flex-start pr-3 h-100%"
		:style="{backgroundImage: `url(${post.featured_image})`, '--primary': post.primary_color}"
		v-for="post in posts.slice(0,1)"
		v-fade-image
	)
		div(class="h-1em flexbox-center-center")
			img(v-if="post.logo" :src="post.logo" class="max-w-3em ml-5em max-h-3em")
		h2(class="font-size-2.5em font-weight-900") Featured: <br>
			span(v-html="post.title")
		router-link(
			:to="{name: 'Post', params: { category: 'case-study', slug: post.slug}}" 
			@click.native="$root.setCircleColor(post.primary_color)"
			class="button $color-white $hover-color-$primary"
		) See Case Study
	div(class="abs t-0 l-0 p-$py-$px flexbox-unset-center !first:ml-1")
		LogoBring
		transition(name="fade")
			CrossIcon(class="$transition-duration-300ms" :style="{transform: `rotate(${viewportIndex * -360}deg)`}" v-show="posts[0] && posts[0].logo")
</template>

<script>
import Slider from "@/components/Slider/Slider.vue";
import SliderPagination from "@/components/Slider/SliderPagination.vue";
import SliderNext from '@/components/Slider/SliderNext.vue';
import SliderPrevious from '@/components/Slider/SliderPrevious.vue';

import CrossIcon from "svg/cross.svg";

export default {
	props: {
		featured: Boolean
	},
	directives: {
		parallax: (el, { value: { slide, strength } }) =>
			(el.style.transform = `translateX(${slide.dIndex * strength * window.innerWidth}px)`),
	},
	components: {
		Slider,
		SliderPagination,
		SliderNext,
		SliderPrevious,
		CrossIcon,
	},
	data() {
		return {
			viewportIndex: 0,
			posts: [],
			currentPostIndex: 0,
		};
	},
	computed: {
		currentPost() {
			return this.posts[this.currentPostIndex] || {};
		}
	},
	async created() {
		this.posts = await this.$wp.getFeaturedCaseStudies();
		if (this.featured) this.posts = this.posts.slice(0,1)
	},
};
</script>
