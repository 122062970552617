import WPAPI from "wpapi";
import Vue from "vue";

import { keyBy, mapValues, get, isDate } from "lodash";

const removeTags = x => x && x.replace(/\<.*?\>|\n/g, "");

import { format } from "date-fns";

const formatDate = date => {
	if (!isDate(date)) {
		date = new Date(date);
	}
	if (!date) return;
	try {
		let result = format(date, "do MMM yyyy");
		return result;
	} catch (err) {
		console.error(err);
	}
};

const getFeaturedImage = post => get(post, "_embedded['wp:featuredmedia'][0].source_url");

const getPrimaryColor = post => {
	const primary_color = post.acf?.primary_color;
	return (primary_color === 'custom' ? post.acf?.custom_color : `var(--${primary_color})`) || 'var(--pink)'
}

export const preparePost = post => ({
	id: post.id,
	logo: post.acf?.logo,
	logo_dark: post.acf?.logo_dark,
	title: post.title.rendered,
	excerpt: removeTags(post.excerpt.rendered),
	slug: post.slug,
	featured_image: getFeaturedImage(post),
	date: post.date,
	date_formatted: formatDate(post.date),
	content: post.content.rendered,
	primary_color: getPrimaryColor(post),
});

export const prepareFeedItem = post => ({
	id: post.id,
	title: post.title.rendered,
	excerpt: removeTags(post.excerpt.rendered),
	external_url: post.acf?.external_url,
	url: (post.acf?.external_url && post.acf?.url) || `/news-feed/${post.slug}`,
	featured_image: getFeaturedImage(post),
	slug: post.slug,
	content: post.content.rendered,
	primary_color: getPrimaryColor(post),
	date: post.date,
	date_formatted: formatDate(post.date),
});

const tryParse = string => {
	try {
		return JSON.parse(string);
	} catch (err) {
		return null;
	}
};

const $wp = ({ endpoint }) => {
	const cache = {};
	return new Vue({
		computed: {
			$request() {
				const wp = new WPAPI({ endpoint });
				return wp;
			},
		},
		methods: {
			async getCategories() {
				cache.categories =
					cache.categories ||
					tryParse(document.querySelector('meta[name="categories"]')?.content) ||
					this.$request.categories().then(x => x); // wp-api doesn't return a promise until .then() is called. hence, the no-op function.
				const categories = keyBy(await cache.categories, 'slug');
				return categories;
			},
			async getPage(slug) {
				cache.pages = cache.pages || {};
				cache.pages[slug] =
					cache.pages[slug] ||
					this.$request
						.pages()
						.slug(slug)
						.embed()
						.then(x => x?.[0] || {});
				return cache.pages[slug];
			},
			async getFeaturedCaseStudies() {
				const categories = await this.getCategories();
				const categoryId = categories["case-study"]?.id;
				return (cache.featuredCaseStudies =
					cache.featuredCaseStudies ||
					this.$request
						.posts()
						.categories(categoryId)
						.sticky(true)
						.embed()
						.then(x => x?.map(preparePost) || []));
			},
			async getFeedItems() {
				const categories = await this.getCategories();
				const categoryId = categories["news-feed"]?.id;
				return (cache.feedItems =
					cache.feedItems ||
					this.$request
						.posts()
						.categories(categoryId)
						.perPage(5)
						.embed()
						.then(x => x?.map(prepareFeedItem)) || []);
			},
		},
	});
};

export default {
	install(Vue, options) {
		Vue.prototype.$wp = $wp(options);
	},
};
