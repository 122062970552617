<template lang="pug">
#home
	Landing
	SliderOurWork
	SectionDuotone(class="$primary-$red $secondary-$yellow d:font-size-3vmax")
		div(class="flexbox-column-center-center w-100% h-100% flex-1 !first:mt-4 font-size-3vmax font-weight-900")
			h2 {{page.third_section_copy}}
			router-link(
				:to="{name: 'What We Do'}" 
				class="button d:font-size-2" 
				:class="{hover: howWeDoItHover}" 
				@mouseenter.native="howWeDoItHover = true" 
				@mouseleave.native="howWeDoItHover = false"
			) What We Do 
	SliderFeed#feed
	SectionBookings
	VFooter
</template>

<script>
import SliderOurWork from "@/components/SliderOurWork.vue";
import SliderFeed from '@/components/SliderFeed.vue';
import SectionTalent from '@/components/SectionTalent.vue';
import SectionBookings from '@/components/SectionBookings.vue';
import SectionDuotone from "@/components/SectionDuotone.vue";

import Landing from "@/components/Landing.vue";

export default {
	name: "Home",
	components: {
		SliderOurWork,
		SliderFeed,
		SectionDuotone,
		Landing,
		SectionTalent,
		SectionBookings,
	},
	data() {
		return {
			feedSliderCurrent: 0,
			feedSliderTotal: 0,
			page: {},
			howWeDoItHover: false,
		};
	},
	async created() {
		this.$emit("loading", Promise.allSettled([
			this.getPage(),
			this.$wp.getFeaturedCaseStudies(),
			this.$wp.getFeedItems(),
		]));
	},
	methods: {
		async getPage() {
			this.page = await this.$wp.getPage('landing').then(x => ({
				third_section_copy: x.acf?.third_section_copy,
			}))
		},
		feedSliderGo(...x) {
			return this.$refs.feedSlider.go(...x);
		},
	},
};
</script>
