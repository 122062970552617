<template lang="pug">
transition
	.menu(
		class="absolute-stretch position-fixed bg-$green color-$pink flexbox-column-center-center font-size-2 child:m-0" 
		:class="{expanded, contracting, 'cursor-pointer': !expanded, 'pointer-events-none': !expanded}" 
		:style="{'--x': circleOffset.x + 'px', '--y': circleOffset.y + 'px'}" 
		@transitionend.self="contracting = false"
		@click="!expanded && (expanded = true)"
	)
		router-link(:to="{name: 'Home'}" @click.native.stop="expanded = false")
			| Home
		router-link(:to="{ name: 'Our Work'}" @click.native.stop="expanded = false")
			| Our Work
		router-link(:to="{ name: 'What We Do'}" @click.native.stop="expanded = false")
			| What We Do
		router-link(:to="{ name: 'Feed' }" @click.native.stop="expanded = false")
			| Feed
		a.bookings(href="/bookings")
			| Bookings
			img.circle-new(src="~img/circle-new-menu.png")
		router-link(to="#contact" @click.native.stop.prevent="expanded = false; $root.scrollTo({selector: '#contact'})")
			| Get In Touch
		button(class="absolute-bottom-center p-50px pb-0 focus:outline-none hover:color-$yellow cursor-pointer transition-$transition-hover pointer-events-initial" @click="onButtonClick")
			CrossIcon(class="h-1em" v-if="expanded")
			BarsIcon(class="h-1em" v-else)
</template>

<style lang="sass" scoped>
.menu
	--duration-circle: 1s
	--easing: ease-in-out
	clip-path: circle(2em at calc(50% + var(--x, 0px)) calc(100% + var(--y, 0px)))
	z-index: var(--z-menu, 100)

	& > a
		--delay-start: 0s
		--delay-stagger: .05s
		transition: var(--transition-hover), transform .8s var(--easing)
		transform: translateY(10vh)
		font-weight: 900
		font-size: 1.5em
		@breakpoint desktop
			font-size: 2em
		&:hover, &:focus
			color: var(--yellow)
		&:nth-child(1)
			transition-delay: var(--delay-start)
		&:nth-child(2)
			transition-delay: calc(var(--delay-start) + 1 * var(--delay-stagger))
		&:nth-child(3)
			transition-delay: calc(var(--delay-start) + 2 * var(--delay-stagger))
		&:nth-child(4)
			transition-delay: calc(var(--delay-start) + 3 * var(--delay-stagger))
		&:nth-child(5)
			transition-delay: calc(var(--delay-start) + 4 * var(--delay-stagger))
		&:nth-child(6)
			transition-delay: calc(var(--delay-start) + 5 * var(--delay-stagger))

.contracting
	transition: clip-path 1s var(--easing)
	clip-path: circle(2em at 50% 100%)
	& > a
		--delay-start: .2s
		&:nth-child(6)
			transition-delay: var(--delay-start)
		&:nth-child(5)
			transition-delay: calc(var(--delay-start) + 1 * var(--delay-stagger))
		&:nth-child(4)
			transition-delay: calc(var(--delay-start) + 2 * var(--delay-stagger))
		&:nth-child(3)
			transition-delay: calc(var(--delay-start) + 3 * var(--delay-stagger))
		&:nth-child(2)
			transition-delay: calc(var(--delay-start) + 4 * var(--delay-stagger))
		&:nth-child(1)
			transition-delay: calc(var(--delay-start) + 5 * var(--delay-stagger))

.expanded
	transition: clip-path 1s var(--easing)
	clip-path: circle(calc(50vw + 50vh) at 50% 50%)
	& > a
		transform: initial

.v-enter-active,
.v-leave-active
	transition: clip-path .3s ease-in-out

.v-enter,
.v-leave-to
	clip-path: circle(0 at 50% 100%)

.bookings
	position: relative
	.circle-new
		position: absolute
		left: calc(100% - 0.2em)
		bottom: calc(100% - 0.7em)
		width: 0.8em
</style>

<script>
import BarsIcon from "./bars-solid.svg";
import CrossIcon from "./times-solid.svg";

import { gravitate } from '@/components/SectionDuotone.vue'

export default {
	props: {
		hide: Boolean,
	},
	components: {
		BarsIcon,
		CrossIcon,
	},
	data() {
		return {
			expanded: false,
			contracting: false,
			mouse: {
				clientX: null,
				clientY: null,
			},
		};
	},
	computed: {
		circleOffset() {
			// TODO: change to physics sim
			return gravitate({
				fromX: this.$innerWidth / 2 - 5,
				fromY: this.$innerHeight - 20,
				toX: this.mouse.clientX,
				toY: this.mouse.clientY,
				radius: 50,
				exponent: 3,
			});
		},
	},
	created() {
		window.addEventListener("mousemove", this.onMouseMove);
	},
	destroyed() {
		window.removeEventListener("mousemove", this.onMouseMove);
	},
	watch: {
		expanded(newValue, oldValue) {
			this.$emit('update:expanded', newValue);
			this.contracting = oldValue && !newValue;
		},
		$route() {
			this.expanded = false;
		},
	},
	methods: {
		onMouseMove(event) {
			// TODO: might need to do an @media check for hover here to exclude mobiles
			this.mouse.clientX = event.clientX;
			this.mouse.clientY = event.clientY;
		},
		onButtonClick(event) {
			if (this.expanded) {
				event.stopPropagation();
				this.expanded = false;
			}
		}
	},
};
</script>
