import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/1_Home.vue";
import OurWork from "@/views/2_OurWork.vue";
import Post from "@/views/3_Post.vue";
import WhatWeDo from "@/views/4_WhatWeDo.vue";
import Feed from "@/views/5_Feed.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home,
	},
	{
		path: "/our-work",
		name: "Our Work",
		component: OurWork,
	},
	{
		path: "/what-we-do",
		name: "What We Do",
		component: WhatWeDo,
	},
	{
		path: "/news-feed",
		name: "Feed",
		component: Feed,
	},
	{
		path: "/:category/:slug",
		name: "Post",
		component: Post,
		props: true,
	},
	{
		path: "*",
		redirect: "/"
	}
];

const router = new VueRouter({
	mode: "history",
	routes,
	async scrollBehavior(to, from, savedPosition) {
		router.app.$root.scrollBehavior({to, from, savedPosition});
		await new Promise(resolve => {});
	},
});

export default router;
