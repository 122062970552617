// adds the v-fade-image directive to fade in images once they've loaded
// can be placed on any element
// the element will be hidden until all <img> tags and background-images inside the element have loaded
// the Web Animation API is used to fade in the element

import { loadImage, timeout } from './_utils.js';
import { uniq } from 'lodash';

// extracts the first url string (i.e. the argument of a url(...) function) from a CSS property value string
const extractCssUrl = value => {
	const match = value.match(/url\(['"]?([^"']*)['"]?\)/);
	return match && match[1];
};

const getBackgroundImageUrls = el => {
	const backgroundImage = window.getComputedStyle(el).backgroundImage || "";
	let results = [];
	const url = extractCssUrl(backgroundImage);
	if (url) results.push(url);
	for (let child of el.children) {
		results = results.concat(getBackgroundImageUrls(child));
	}
	return results;
}

const getImageElementUrls = el => {
	const images = []
	// check if the element itself is an <img>
	if (el.tagName.match(/^img$/i) && el.src) images.push(el.src);
	// check for nested <img> elements
	const imgEls = el.getElementsByTagName("img");
	for (const imgEl of imgEls) {
		if (imgEl.src) images.push(imgEl.src);
	}
	return images;
}

// extract an array of all <img> element src's and background images urls inside an element
const getAllUniqueImageUrls = el => {
	return uniq([
		...getBackgroundImageUrls(el),
		...getImageElementUrls(el)
	]);
};

export const fadeImage = {
	async bind(el) {
		const animation = el.animate(
			[{ opacity: 0, offset: 0 }],
			{fill: "both", duration: 300}
		)
		animation.onfinish = () => {
			animation.cancel();
		}
		animation.pause();
		await timeout();
		const images = getAllUniqueImageUrls(el);
		// waits for all images to load or error
		await Promise.allSettled(images.map(loadImage));
		// then use the Web Animation API to fade in the element
		animation.play();
	}
}

export default {
	install(Vue, options) {
		Vue.directive('fade-image', fadeImage);
	}
}