<template lang="pug">
div(class="grid-feed bg-white")
	component(
		:is="post.external_url ? 'a' : 'router-link'"
		:to="post.url" 
		:href="post.url" 
		v-for="post in postsLimited" 
		:key="post.id" 
		class="color-white bg-$primary"
		:style="{'--primary': post.primary_color}"
		:target="post.external_url ? '_blank' : ''"
	)
		h3(v-html="post.title")
		time(:datetime="post.date") {{post.date_formatted}}
		img(:src="post.featured_image")
		ExternalLinkIcon(class="absolute-bottom-right m-2 font-size-1.5" v-if="post.external_url")
</template>

<script>
import { prepareFeedItem } from "@/plugins/wordpress.js";

import ExternalLinkIcon from "svg/external-link.svg";

export default {
	components: {
		ExternalLinkIcon,
	},
	props: {
		excludeSlugs: {
			// to hide these posts from the grid
			type: Array,
			default: () => [],
		},
		rowLimit: {
			// limit the number of row's shown
			type: Number,
			default: Infinity,
		},
	},
	data() {
		return {
			page: 0,
			totalPages: null,
			loading: false,
			posts: [],
		};
	},
	computed: {
		postsPerRow() {
			return this.$large ? 3 : 2;
		},
		postsFiltered() {
			return this.posts.filter(x => !this.excludeSlugs.includes(x.slug));
		},
		postLimit() {
			return this.rowLimit * this.postsPerRow;
		},
		postsLimited() {
			return this.postsFiltered.slice(0, this.postLimit);
		},
	},
	created() {
		this.loadMore();
		window.addEventListener("scroll", this.onScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.onScroll);
	},
	methods: {
		onScroll() {
			const rect = this.$el.getBoundingClientRect();
			if (rect.y + rect.height < 1.5 * window.innerHeight) {
				this.loadMore();
			}
		},
		async loadMore() {
			if (this.page === this.totalPages || this.loading || this.posts.length >= this.postLimit) return;
			this.loading = true;
			try {
				const categories = await this.$wp.getCategories();
				const categoryId = categories["news-feed"]?.id;
				const posts = await this.$wp.$request
					.posts()
					.categories(categoryId)
					.perPage(12)
					.page(this.page + 1)
					.embed();

				this.totalPages = posts._paging.totalPages;

				const newPosts = posts.map(prepareFeedItem);

				this.posts = this.posts.concat(newPosts);

				this.page++;
			} catch (err) {
				console.error(err);
			} finally {
				this.loading = false;
			}
		},
	},
};
</script>

<style lang="sass" scoped>
.grid-feed
	text-align: center
	display: grid
	--columns-count: 1
	grid-template-columns: repeat(var(--columns-count), 1fr)
	--aspect-ratio: calc(16 / 9)
	@breakpoint small
		--aspect-ratio: 1
		--columns-count: 2
	@breakpoint large
		--columns-count: 3

	a
		display: block
		flexbox: column center center
		padding: 2rem
		min-height: calc(100vw / var(--aspect-ratio) / var(--columns-count))
		font-weight: 900
		position: relative
		& > *
			z-index: 1
		& > * + *
			margin-top: 1rem
		h3
			font-size: 2.5em
		time
			font-weight: 400
		img
			absolute: stretch
			margin: 0
			width: 100%
			height: 100%
			object-fit: cover
			z-index: 0
			transition: opacity .3s linear
		&:hover img
			opacity: .5
</style>
