// adds methods to set timeouts and intervals that are automatically cleared when the component is destroyed

import { timeout } from './_utils.js';

export const mixin = {
	data() {
		// private variables to hold lists of all the timeouts and intervals
		return {
			// eslint-disable-next-line
			$_timeouts: [],
			// eslint-disable-next-line
			$_intervals: [],
		};
	},
	methods: {
		// returns a setTimeout promise that resolves after a number of milliseconds
		// promise is cancelled when `this` instance is destroyed
		$setTimeout(ms) {
			const timer = timeout(ms);
			this.$data.$_timeouts.push(timer);
			return timer;
		},
		$clearTimeouts() {
			this.$data.$_timeouts.forEach(x => x && typeof x.cancel === "function" && x.cancel());
		},
		// sets up an interval thats cancelled when `this` instance is destroyed
		$setInterval(callback, ms) {
			const interval = setInterval(callback, ms);
			this.$data.$_intervals.push(interval);
			return interval;
		},
		$clearIntervals() {
			this.$data.$_intervals.forEach(x => clearInterval(x));
		},
	},
	destroyed() {
		this.$clearTimeouts();
		this.$clearIntervals();
	},
};

export default {
	install(Vue, options) {
		Vue.mixin(mixin)
	}
}
