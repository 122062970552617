<template lang="pug">
.landing(class="min-h-100vh bg-$purple color-$yellow text-align-center rel")
	//- TODO: abstract as Landing component
	transition(name="fade" mode="out-in" appear)
		video(v-show="!showVideo" :src="loopSrc" class="absolute-stretch w-100% h-100% object-fit-cover bg-black" autoplay playsinline loop muted ref="loopVideo")
	transition(name="fade" mode="out-in" appear)
		video(v-show="showVideo" :src="videoSrc" class="absolute-stretch w-100% h-100% object-fit-cover m:object-fit-contain bg-black" playsinline controls :muted="!showVideo" ref="video" )
	transition(name="fade")
		button(class="absolute-top-right p-2 font-size-2" v-show="showVideo" @click="showVideo = false")
			TimesIcon
	transition(name="fade" class="$transition-duration-1s")
		div(class="absolute-stretch flexbox-column-center-center p-20vh-5vh-5vh bg-$purple@60%" v-show="!showVideo")
			LogoBring(class="font-size-25vmin d:mr--.25em mb-2")
			p(class="font-size-5.5vmin font-weight-600 letter-spacing-.02em mr--1em mb-10vh") We are BRING agency.<br>The Catalysts of Culture.
			//- TODO: add gravitation to the circles
			div(class="size-2vh md:size-2.5vmin lg:size-3vmin font-weight-900 bg-$yellow color-$purple absolute-stretch $x-5em lg:$y-70vh $y-80vh" style="clip-path: circle(6em at var(--x) var(--y))") 
				button(class="abs t-$y l-$x transform-translate(-50%,-50%)" @click="showVideo = true") Watch<br/> Our Reel&nbsp;
					PlayCircle(class="inline h-.8em")
			div(class="size-2vh md:size-2.5vmin lg:size-3vmin font-weight-900 bg-$yellow color-$purple absolute-stretch $x-(100%-5em) $y-20%" style="clip-path: circle(4em at var(--x) var(--y))") 
				div(class="abs t-$y l-$x transform-translate(-50%,-50%) white-space-nowrap")
					img(src="~img/logo-universal-purple.png" class="w-6em mb-.5")
					p(class="font-size-.35em") Part of the Universal Music Group
</template>

<script>
import PlayCircle from "svg/play-circle.svg";
import TimesIcon from "svg/times.svg";

export default {
	components: {
		PlayCircle,
		TimesIcon,
	},
	data() {
		return {
			showVideo: false,
			post: {},
			inProduction: process.env.NODE_ENV === "production",
			muteVideo: true,
		};
	},
	computed: {
		loopSrc() {
			return this.$desktop
				? "https://player.vimeo.com/progressive_redirect/playback/530573234/rendition/1080p/file.mp4?loc=external&signature=d83bc16ae9438d00168fe50a85006ee05c669d45357d02ced16f33e9d94cf31e"
				: "https://player.vimeo.com/progressive_redirect/playback/530573234/rendition/720p/file.mp4?loc=external&signature=8b1fbbac1bfda298808385d189e7e92b16b85541565ba157ea1b157c65813a1f";
		},
		videoSrc() {
			return this.$desktop
				? "https://player.vimeo.com/progressive_redirect/playback/538462529/rendition/1080p/file.mp4?loc=external&signature=7526844c067c59cf8e858802bd7b242b97955251a3864fe902f948f9171c8040"
				: "https://player.vimeo.com/progressive_redirect/playback/538462529/rendition/720p/file.mp4?loc=external&signature=33ea0509239316cd5c454ddd7abf7ce26723eea9fd6641984d3ef328594f0fd7";
		},
	},
	watch: {
		async showVideo(value) {
			this.$root.showMenu = !value;
			if (value) {
				this.$refs.video.play()
				this.$refs.loopVideo.pause()
			} else {
				this.$refs.video.pause()
				this.$refs.loopVideo.play()
			}
		},
	},
	async created() {
		this.post = await this.$wp.getPage("landing").then(x => ({
			video: x.acf?.video,
		}));
	},
	mounted() {
		window.addEventListener("scroll", this.onScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.onScroll);
	},
	methods: {
		onScroll() {
			this.showVideo = false;
		},
	},
};
</script>
