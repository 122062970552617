<template lang="pug">
div
	SliderFeed(logo)
	GridFeed(:exclude-slugs="excludeSlugs")
	VFooter
</template>

<script>
import SliderFeed from "@/components/SliderFeed.vue";
import GridFeed from "@/components/GridFeed.vue";

export default {
	components: {
		SliderFeed,
		GridFeed,
	},
	async created() {
		this.$emit(
			"loading",
			this.$wp.getFeedItems().then(posts => (this.posts = posts))
		);
	},
	data() {
		return {
			posts: [],
		};
	},
	computed: {
		excludeSlugs() {
			return this.posts.map(x => x.slug) || [];
		},
	},
};
</script>
