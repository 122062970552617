<template>
	<button
		@click="previous"
		class="slider-previous"
		:disabled="disabledComputed"
	>
		<svg viewBox="0 0 24 24" width="24px" height="24px">
			<path d="M15.41 16.59L10.83 12l4.58-4.59L14 6l-6 6 6 6 1.41-1.41z" fill="currentColor"></path>
		</svg>
	</button>
</template>

<style scoped>
button {
	position: absolute;
	left: 0;
	top: 50%;
	transform: translateY(-50%);
	background: initial;
	border: initial;
	cursor: pointer;
}

button:disabled { 
	cursor: not-allowed;
	opacity: .5;
}

button:focus {
	outline: none;
}
</style>

<script>
export default {
	props: {
		previous: Function,
		disabled: Boolean,
		previousDisabled: Boolean,
	},
	computed: {
		disabledComputed() {
			return this.disabled || this.previousDisabled;
		},
	},
};
</script>
