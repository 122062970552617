<template lang="pug">
div
	SectionDuotone(class="$primary-$purple $secondary-$yellow d:font-size-2 font-weight-600 letter-spacing-.02em overflow-hidden" )
		LogoBring(class="absolute-top-left m-2")
		div(class="absolute-top-right bg-$color color-$hover-color font-size-3vmin m:font-size-4vmin w-8em h-8em b-radius-9999px mr-1em mt-2em flexbox-column-center-center white-space-no-wrap" v-if="$desktop")
			LogoUniversal(class="w-6em mb-.5 h-auto")
			p(class="font-size-.35em") Part of the Universal Music Group
		div(class="flexbox-column-center-center min-h-70vh m:min-h-90vh m:font-size-1.5")
			h2(class="mb-2 font-size-2.5em m:mt-2") What We Do
			p(class="d:max-w-70%") Born from the world's biggest music company, we're unmatched in creating and marketing the planet's most influential artists&hellip; after all, we've been doing so for 100+ years. 
				| By leveraging Universal Music's world-class tools, we create Cultural Capital for Australian brands, turning fan love into brand love. We do it by harnessing unparalleled streaming data
				| and bold creative strategy. We then amplify by leveraging a deep understanding of how music fans engage with media.
		template(v-slot:other)
			div(class="grid grid-template-columns-repeat(4,1fr) m:grid-template-columns-repeat(2,1fr) gap-1rem w-100% mt-10vh" ref="whatWeDoGrid" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: whatWeDoAnimation}")
				article(v-for="{label, image} in gridImages" class="bg-$green-center/cover flex-1-1-20% flexbox-center-center p-10% before:content-{} before:w-0 before:pb-100%" :style="`background-image: url('${image}')`")
					h3(class="font-size-inherit" v-html="label")
				article(class="child:m-0 font-weight-900 flexbox-column-center-center m:grid-column-span-2")
					p(class="font-size-6") +
					p(class="font-style-italic") Plus so much more...
					router-link.button(to="#contact" class="d:font-size-1.5 m-1.5" @click.native="$root.scrollTo({selector: '#contact'})") Get in touch
	div(class="rel h-300vh" ref="years" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: yearsAnimation}")
		section(class="bg-$yellow color-$pink min-h-100vh flexbox-unset-center sticky t-0 rel overflow-hidden")
			p(class="m-0 font-weight-900 font-size-5vw m:font-size-8vw text-align-center p-0-2 d:p-0-13% flexbox-column-center-center max-w-100%" ref="yearsText")
				span(class="font-size-4em line-height-1 m:font-size-40vw") {{yearsRounded}}+
				span Years of expertise creating and reinventing culture
			div(class="absolute-bottom-stretch h-50% child:abs child:h-50em font-size-1vmin m:font-size-.7vw" ref="artistCollage")
				img(src="~img/artists-collage/05.png" class="b--15em r-40em z-index-5 h-55em")
				img(src="~img/artists-collage/04.png" class="b--10em l-38em h-52em z-index-4")
				img(src="~img/artists-collage/06.png" class="b--10em r-15em z-index-4 h-55em")
				img(src="~img/artists-collage/03.png" class="b--3em l-29em h-63em z-index-3")
				img(src="~img/artists-collage/08.png" class="b-0 r--23em z-index-3")
				img(src="~img/artists-collage/02.png" class="b--1em l--4em h-44em z-index-2")
				img(src="~img/artists-collage/07.png" class="b-12em r-20em z-index-2")
				img(src="~img/artists-collage/01.png" class="b-6em l--13em h-60em z-index-1")
				img(src="~img/artists-collage/09.png" class="b-22em r-8em z-index-1")
	section(class="bg-white color-$purple text-align-center section flexbox-column-unset-center" ref="howWeDoIt" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: howWeDoItAnimation, unidirectional: true}")
		h2.fade How we do it
		p.fade(class="d:max-w-50%") Marketing the world's biggest artists requires tools, data and a century of know how. 
			| We have developed the same strategies for brands chasing similar cultural and consumer relevance.
		ul(class="list-icons child:cursor-pointer")
			router-link(tag="li" to="#insights-and-data" @click.native="$root.scrollTo({selector: '#insights-and-data'})")
				img.fade(src="~img/icon-data.png")
				h3.fade Insights and Data
				p.fade Real time streaming data, social listening tools and internal research to deeply understand music fans and how they engage with media.
			router-link(tag="li" to="#strategy-and-consultancy" @click.native="$root.scrollTo({selector: '#strategy-and-consultancy'})")
				img.fade(src="~img/icon-strategy.png")
				h3.fade Strategy and Consultancy
				p.fade Campaign planning, consumer research and insights and Cultural Capital workshops.
			router-link(tag="li" to="#creative" @click.native="$root.scrollTo({selector: '#creative'})")
				img.fade(src="~img/icon-creative.png")
				h3.fade Creative
				p.fade Bespoke creative ideas for brand campaigns and platforms that challenge expectation and connect to culture.
			router-link(tag="li" to="#talent" @click.native="$root.scrollTo({selector: '#talent'})")
				img.fade(src="~img/icon-talent.png")
				h3.fade Talent
				p.fade Finding the biggest and most relevant names to bring ideas to life, matched with consumer streaming data and proprietary company tools.
			router-link(tag="li" to="#media-and-amplification" @click.native="$root.scrollTo({selector: '#media-and-amplification'})")
				img.fade(src="~img/icon-media.png")
				h3.fade Media and Amplification
				p.fade Social amplification through artist's channels, media partnerships and brand safe media buying.
		router-link.button(to="#contact" class="$color-$purple $hover-color-$blue" @click.native="$root.scrollTo({selector: '#contact'})") Get in touch
	section#insights-and-data(class="bg-$purple color-$yellow section" ref="insightsData" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: insightsDataAnimation, unidirectional: true}")
		h3.fade Insights and Data
		p.fade(class="d:max-w-50%") Artists don’t become icons by accident, and neither do brands. We harness streaming data from our global DSP partners and apply research 
			| commissioned by the world's largest music company to uncover listener insights and drive creative ideation that connects
		ul(class="list-icons")
			li
				img.fade(src="~img/icon-swirl.png")
				h3.fade Fueling Art With Science
			li
				img.fade(src="~img/icon-stream.png")
				h3.fade Real-time streaming data
			li
				img.fade(src="~img/icon-magnify.png")
				h3.fade Global and Local Research
			li
				img.fade(src="~img/icon-social-listening.png")
				h3.fade Social listening Tools
	section#strategy-and-consultancy(class="bg-white color-$purple section min-h-100vh rel pb-50vh d:pb-65vh" ref="strategyConsultancy" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: strategyConsultancyAnimation}")
		h3.fade(class="z-index-1") Strategy and Consultancy
		div(class="d:max-w-50% z-index-1")
			p.fade We have written and executed music strategies for Australia’s biggest brands. We do it by discovering a brand's unique voice in music then creating a go to market plan that talks to fans in ways that will keep them engaged.
			p.fade A proven track record of building Cultural Capital for brands.
		div(class="flexbox-wrap-center-center child:max-w-5em child:max-h-3em z-index-1 child:mt-0 !first:ml-1")
			img.fade(src="~img/strategy-brands/kfc.png")
			img.fade(src="~img/strategy-brands/v.png")
			img.fade(src="~img/strategy-brands/mini.jpg")
			img.fade(src="~img/strategy-brands/tinder.png")
			img.fade(src="~img/strategy-brands/amex.png")
		.book(class="absolute-bottom r-50% h-80vh m:h-60vh")
			img(src="~img/book.png" class="h-100% transform-translate(50%,20%) max-w-initial")
	section#creative(class="section bg-$purple color-$yellow" ref="creative" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: creativeAnimation}")
		h3.fade Creative
		div(class="d:max-w-50%")
			p.fade Informed by our data and strategy and working closely with artists, we create original and culturally relevant ideas for our brand partners. We then build campaigns and platform ecosystems that excite, inspire and engage music fans.
			p.fade Check out some of our latest work
		ul.list-case-studies
			router-link.reveal(tag="li" :to="{ name: 'Post', params: { category: 'case-study', slug: post.slug } }" v-for="post in featuredPosts" :key="post.id" :style="{'--primary': post.primary_color}" )
				img(:src="post.featured_image" v-fade-image)
				div
					h4(v-html="post.title")
	SectionTalent(class="bg-white color-$purple")
	section#media-and-amplification(class="section bg-$blue color-$purple" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: mediaAmplificationAnimation, unidirectional: true}" ref="mediaAmplification")
		h3.fade Media and Amplification
		div(class="d:max-w-50%")
			p.fade Once we’ve cracked the strategy and creative idea, we look at the best ways to amplify the idea to music fans. Leveraging the world's biggest music businesses tools, we can accelerate the ways fans engage with our brand campaigns, creating meaningful connections.
			p.fade 
				em Finding the right eyes and ears.
		ul.list-icons
			li
				img.fade(src="~img/icon-social-media-buying.png")
				h3.fade Social Amplification
				p.fade Access artist socials, UMG owned channels and unique look-a-like audience amplification options
			li
				img.fade(src="~img/icon-vevo.png" class="pt-2em pb-2em")
				h3.fade Media Partnerships
				p.fade Access our partner channels to drive true scale to connect to the right medium where music fans are most engaged with your content
			li
				img.fade(src="~img/icon-partnerships.png")
				h3.fade Media and Publishers
				p.fade Authentic partnerships with youth publications and other music media to deliver brands to fans in meaningful ways
	section#reporting-and-success-measurement(class="section bg-white color-$purple" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: reportingSuccessAnimation, unidirectional: true}" ref="reportingSuccess")
		h3.fade Reporting and Success Measurement 
		div(class="d:max-w-50%")
			p.fade 
				| Defining what success will look like and how we hold ourselves accountable helps set parameters for our thinking. It also creates space for learning. Optimising our activations, 
				| highlighting areas where we need to improve as well as understanding why we see success in others. Here are just some of the tools we offer brands for their campaigns.
		ul.list-icons.large-icons
			li
				img.fade(src="~img/icon-fan-engagement.jpg")
				h3.fade Fan Engagement and Phasing
				p.fade With a deep understanding of how fans engage with music and entertainment, we can structure campaigns to talk to all types of fan. We can then track levels of engagement at each phase to optimise our campaigns in real time.
			li
				img.fade(src="~img/icon-brand-perception.jpg")
				h3.fade Brand Perception and Impact
				p.fade Using our internally developed ViewPoints tool, we are able to track consumer perception both pre and post campaign to understand the impact of our work. We can set targets of brand shifts and understand the significance of change.
			li
				img.fade(src="~img/icon-affinity.jpg")
				h3.fade Affinity and ROI
				p.fade Across all our talent and brands content, we can track numerous quantitative data points. From the number of impressions, engagements and sentiment, to looking at the media value of talent posts and ultimately their ROI. 
	VFooter
</template>

<script>
import SectionDuotone from "@/components/SectionDuotone.vue";

import scrollMixin from "@/components/SectionDuotone.vue";

import { clamp } from "lodash";

import anime from "animejs";

import {sectionAnimation, offsetInParent} from '@/plugins/_utils.js'

export const scrollProgress = (el, binding) => {
	if (typeof binding.value !== "object") {
		throw new Error("Binding for v-scroll-progress must be a object");
	}
	const { scrollTop, innerHeight, callback, animation, unidirectional = false } = binding.value;
	if (typeof callback !== "function" && typeof animation !== "object") {
		throw new Error("callback or animation must be passed to v-scroll-progress");
	}
	if (scrollTop === undefined) {
		throw new Error("scrollTop must be passed to v-scroll-progress");
	}
	if (innerHeight === undefined) {
		throw new Error("innerHeight must be passed to v-scroll-progress");
	}
	const offsetTop = offsetInParent(el, document.scrollingElement).top,
		offsetHeight = el.offsetHeight;

	if (scrollTop == null || !offsetHeight) return;

	const start = Math.max(offsetTop - innerHeight, 0);
	const end = start + offsetHeight + innerHeight;
	const progress = ((clamp(scrollTop, start, end) - start) / innerHeight) * 1000;
	if (typeof callback === "function") {
		callback(progress);
	}
	if (animation && typeof animation.seek === "function") {
		let minProgress = unidirectional ? (animation.progress / 100) * animation.duration : 0;
		animation.seek(Math.max(minProgress, progress));
	}
};

import Vue from 'vue'
Vue.directive('scroll-progress', scrollProgress)

const yearsMixin = {
	data() {
		return {
			yearsAnimation: null,
			years: 0,
		};
	},
	computed: {
		yearsRounded() {
			return Math.round(this.years);
		},
	},
	mounted() {
		this.yearsAnimation = anime
			.timeline({
				autoplay: false,
				easing: "linear",
			})
			.add(
				{
					targets: this.$data,
					years: [0, 100],
					duration: 2000,
				},
				250
			)
			.add(
				{
					targets: this.$refs.yearsText,
					translateY: ["20%", "-80%"],
					duration: 4000,
				},
				0
			)
			.add(
				{
					targets: this.$refs.artistCollage.children,
					opacity: [0, 1],
					duration: 400,
					delay: anime.stagger(200),
				},
				1000
			)
			.add(
				{
					targets: this.$refs.artistCollage.children,
					translateY: el => {
						const zIndex = parseInt(window.getComputedStyle(el).zIndex);
						return [zIndex * 5 + "em", "0em"];
					},
					duration: 3000,
				},
				1000
			);
	},
};

const whatWeDoMixin = {
	components: {
		YoutubeIcon: require("svg/youtube.svg?inline"),
		AppleIcon: require("svg/apple.svg?inline"),
		SpotifyIcon: require("svg/spotify.svg?inline"),
	},
	data() {
		return {
			whatWeDoAnimation: null,
			howWeDoItAnimation: null,
			insightsDataAnimation: null,
			strategyConsultancyAnimation: null,
			creativeAnimation: null,
			mediaAmplificationAnimation: null,
			reportingSuccessAnimation: null,
			featuredPosts: [],
		};
	},
	async mounted() {
		this.createAnimations();
	},
	watch: {
		$innerHeight: "createAnimations",
		$innerWidth: "createAnimations",
	},
	methods: {
		async createAnimations() {
			this.whatWeDoAnimation = anime
				.timeline({
					autoplay: false,
					easing: "linear",
				})
				.add(
					{
						targets: this.$refs.whatWeDoGrid.children,
						opacity: [0, 1],
						translateX: [anime.stagger(25, { grid: [4, 3], from: "center", axis: "x" }), 0],
						translateY: [anime.stagger(25, { grid: [4, 3], from: "center", axis: "y" }), 0],
						rotateZ: [anime.stagger([0, 10], { grid: [4, 3], from: "center", axis: "x" }), 0],
						delay: anime.stagger(800 / 12),
						duration: 200,
					},
					0
				);

			this.howWeDoItAnimation = sectionAnimation(this.$refs.howWeDoIt);
			this.insightsDataAnimation = sectionAnimation(this.$refs.insightsData);
			this.strategyConsultancyAnimation = sectionAnimation(this.$refs.strategyConsultancy);
			this.mediaAmplificationAnimation = sectionAnimation(this.$refs.mediaAmplification);
			this.reportingSuccessAnimation = sectionAnimation(this.$refs.reportingSuccess);

			await this.$wp.getFeaturedCaseStudies().then(posts => (this.featuredPosts = posts.slice(0, 4)));
			this.creativeAnimation = sectionAnimation(this.$refs.creative);
		},
	},
};

import SectionTalent from "@/components/SectionTalent.vue";

export default {
	mixins: [scrollMixin, whatWeDoMixin, yearsMixin],
	directives: {
		scrollProgress,
	},
	components: {
		SectionDuotone,
		LogoUniversal: require("svg/logo-universal.svg?inline"),
		SectionTalent,
	},
	data() {
		return {
			page: {},
		};
	},
	async created() {
		this.$emit("loading", this.getPage());
	},
	computed: {
		gridImages() {
			const data = this.page.grid_images || Array.from(new Array(11), () => ({}));
			return this.$desktop ? data : data.slice(0, 8);
		},
	},
	methods: {
		async getPage() {
			this.page = await this.$wp.getPage("what-we-do").then(page => ({
				grid_images: page.acf?.grid_images,
				artist_images: page.acf?.artist_images.map(x => ({
					src: x.url,
					name: x.title,
				})),
			}));
		},
	},
};
</script>

<style lang="sass" scoped>
.list-icons
	text-align: center
	flexbox: space-evenly

	& > * + *
		margin-top: 0



	li
		flex: 1 0
		font-weight: 500
		padding: 2rem
		line-height: 1.4
		font-size: 1rem
		& > *
			max-width: 24em
		img, svg
			height: 8em
			max-width: initial
		* + *
			margin-top: 1rem
		h3
			font-size: 1.5rem
			font-weight: 900
		svg
			height: auto
			width: 100%

	&.large-icons
		img, svg
			height: 12em
			@breakpoint medium:not-large
				height: initial
				width: 12em

	@breakpoint medium:not-large
		flex-direction: column
		li
			display: grid
			align-items: center
			grid-template-columns: auto 1fr
			max-width: initial
			text-align: left
			grid-column-gap: 2rem
			img, h3:last-child
				grid-row: span 2
			img, svg
				width: 8em
				height: initial

	@breakpoint mobile
		flex-direction: column

.list-case-studies
	flexbox: column
	max-width: 150vh
	margin-left: auto
	margin-right: auto
	width: 100%
	li
		position: relative
		width: 65%
		margin-top: 0
		display: flex
		text-align: center
		padding: 2rem
		cursor: pointer
		background-color: var(--primary)
		color: white
		img
			height: 100%
			absolute: stretch
			z-index: -1
			object-fit: cover
			opacity: 1
			transition: opacity .3s linear
		&:hover
			img
				opacity: .5
		@breakpoint mobile
			width: 90%
			padding: 2rem 1rem 2rem
		& > *
			flexbox: column center center
			flex: 1
		&:nth-child(even)
			align-self: flex-end
		&:not(:last-child)
			margin-bottom: -6rem
			@breakpoint mobile
				margin-bottom: -2rem
				padding-bottom: 4rem
		&::before
			content: ""
			padding-bottom: 75%
			width: 0

		h1, h2, h3, h4, h5, h6
			font-size: 3rem
		time
			font-weight: 400
</style>
