// adds computed properties into all components relating to screen size and breakpoint information

export default {
	install(Vue, options) {
		// create a private data store for our computed props to be based off
		// Vue.observable ensures that the computed properties are recalculated when these data properties change
		const state = Vue.observable({
			innerWidth: 0,
			innerHeight: 0,
		});

		// set up a single set of window listeners
		function update() {
			state.innerHeight = window.innerHeight;
			state.innerWidth = window.innerWidth;
		}
		window.addEventListener("resize", update);
		window.addEventListener("orientationchange", update);
		update();

		// these computed properties can be accessed by any components/views in the app
		Vue.mixin({
			computed: {
				$innerWidth() {
					return state.innerWidth;
				},
				$innerHeight() {
					return state.innerHeight;
				},
				$small() {
					return state.innerWidth > 480;
				},
				$medium() {
					return state.innerWidth > 768;
				},
				$large() {
					return state.innerWidth > 1024;
				},
				$xl() {
					return state.innerWidth > 1280;
				},
				$portrait() {
					return state.innerWidth < state.innerHeight;
				},
				$landscape() {
					return !this.$portrait;
				},
				$desktop() {
					return this.$medium;
				},
				$mobile() {
					return !this.$desktop;
				},
			},
		});
	},
};
