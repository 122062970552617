<template lang="pug">
div
	SliderOurWork(featured)
	GridOurWork(:exclude-slugs="excludeSlugs")
	VFooter
</template>

<script>
import SliderOurWork from "@/components/SliderOurWork.vue";
import GridOurWork from "@/components/GridOurWork.vue";

export default {
	components: {
		SliderOurWork,
		GridOurWork,
	},
	data() {
		return {
			posts: [],
		};
	},
	computed: {
		excludeSlugs() {
			return this.posts.map(x => x.slug).slice(0,1) || [];
		},
	},
	async created() {
		this.$emit(
			"loading",
			this.$wp.getFeaturedCaseStudies().then(posts => (this.posts = posts))
		);
	},
};
</script>
