<template lang="pug">
section(class="rel min-h-100vh bg-$primary color-$secondary flexbox-column-unset-center text-align-center $p-3em p-$p m:$p-2rem $color-$secondary $hover-color-$primary")
	div(class="flex-1 flexbox-column-unset-center")
		slot
	div(class="absolute-stretch flexbox-column-unset-center clip-path1 p-$p bg-$secondary color-$primary $color-$primary $hover-color-$secondary" aria-hidden="true" :style="{'--x': circle1.x + 'px', '--y': circle1.y + 'px'}" ref="circle1")
		slot
	div(class="absolute-stretch flexbox-column-unset-center clip-path2 p-$p bg-$secondary color-$primary $color-$primary $hover-color-$secondary" aria-hidden="true" :style="{'--x': circle2.x + 'px', '--y': circle2.y + 'px'}" ref="circle2")
		slot
	div(v-if="$slots.other" class="z-index-1 w-100%")
		slot(name="other")
</template>

<style lang="sass" scoped>
.clip-path1
	--size: 16vw
	clip-path: circle(var(--size) at calc(var(--p) + var(--x)) calc(50vh - 2em + var(--y)))
	@breakpoint mobile
		--size: 32vw
.clip-path2
	--size: 12vw
	clip-path: circle(var(--size) at calc(100vw - var(--p) + var(--x)) calc(50vh + 2em + var(--y)))
	@breakpoint mobile
		--size: 24vw
</style>

<script>
export const gravitate = ({ fromX, fromY, toX, toY, radius, strength = 0.5, exponent = 2 }) => {
	if (
		isNaN(fromX) ||
		isNaN(fromY) ||
		isNaN(toX) ||
		isNaN(toY) ||
		isNaN(radius) ||
		isNaN(strength) ||
		isNaN(exponent)
	) {
		return { x: 0, y: 0 };
	}
	const dx = toX - fromX,
		dy = toY - fromY,
		maxOffset = radius * strength,
		maxOffsetAt = radius + maxOffset,
		distance = Math.sqrt(dx ** 2 + dy ** 2),
		d = distance / maxOffsetAt, // scale distance so that max offset happens at d = 1
		// I generated the following equation by just playing around with a graphic calculator until I got the easing function I wanted
		offsetDistance = (d ** exponent / Math.exp(exponent * (d - 1))) * maxOffset,
		scalingFactor = distance === 0 ? 0 : offsetDistance / distance;
	return offsetDistance < 0.5
		? { x: 0, y: 0 }
		: {
				x: dx * scalingFactor,
				y: dy * scalingFactor,
		  };
};

export const scrollMixin = {
	data() {
		return {
			window: {
				scrollTop: null,
				scrollLeft: null,
			},
		};
	},
	mounted() {
		this.onScroll();
		window.addEventListener("scroll", this.onScroll);
	},
	destroyed() {
		window.removeEventListener("scroll", this.onScroll);
	},
	methods: {
		onScroll() {
			this.window.scrollTop = document.scrollingElement.scrollTop;
			this.window.scrollLeft = document.scrollingElement.scrollLeft;
		},
	},
};

export default {
	mixins: [scrollMixin],
	data() {
		return {
			mouse: {
				clientX: null,
				clientY: null,
			},
			circle1: {
				radius: null,
				baseX: null,
				baseY: null,
				x: 0,
				y: 0,
				lastX: 0,
				lastY: 0,
			},
			circle2: {
				radius: null,
				baseX: null,
				baseY: null,
				x: 0,
				y: 0,
				lastX: 0,
				lastY: 0,
			},
		};
	},
	mounted() {
		this.updateCircleProperties();
		this.onScroll();
		this.onFrame();
	},
	created() {
		window.addEventListener("mousemove", this.onMouseMove);
		window.addEventListener("resize", this.updateCircleProperties);
		this.$emit('load-end')
	},
	destroyed() {
		window.removeEventListener("mousemove", this.onMouseMove);
		window.removeEventListener("resize", this.updateCircleProperties);
	},
	methods: {
		onMouseMove(event) {
			// TODO: might need to do an @media check for hover here to exclude mobiles
			this.mouse.clientX = event.clientX;
			this.mouse.clientY = event.clientY;
		},
		updateCircleProperties() {
			this.mouse.pageX = null;
			this.mouse.pageY = null;
			const clipPathRegex = /([\d\w\.]+) at ([\d\w\.]+) ([\d\w\.]+)/;

			//circle1
			if (this.$refs.circle1) {
				const circle1ClipPath = window.getComputedStyle(this.$refs.circle1).clipPath;
				let match = circle1ClipPath.match(clipPathRegex);
				this.circle1.radius = parseFloat(match[1]);
				this.circle1.baseX = parseFloat(match[2]);
				this.circle1.baseY = parseFloat(match[3]);
			}

			//circle2
			if (this.$refs.circle2) {
				const circle2ClipPath = window.getComputedStyle(this.$refs.circle2).clipPath;
				let match = circle2ClipPath.match(clipPathRegex);
				this.circle2.radius = parseFloat(match[1]);
				this.circle2.baseX = parseFloat(match[2]);
				this.circle2.baseY = parseFloat(match[3]);
			}
		},
		onFrame() {
			window.requestAnimationFrame(this.onFrame);

			const updateCircle = circle => {
				// constants
				const dmax = circle.radius * 1.5, // the mouse distance that will result in the maximum circle deflection
					maxForce = 0.1, // between 0 and 1, closer to 1 makes circles "snap" to the mouse
					springStrength = 0.1,
					exponent = 1.7, // how quickly the circles spring back to their origins when the circle moves our of range
					forceStrength = dmax ** exponent * springStrength,
					damping = 0.5; // between 0 and 1, closer to 1 creates more viscous movement

				let x = circle.x,
					y = circle.y;

				// apply attraction force towards mouse
				if (this.$el && this.mouse.clientX && this.mouse.clientY) {
					const fromX = this.$el && this.$el.offsetLeft + circle.baseX + circle.x,
						fromY = this.$el && this.$el.offsetTop + circle.baseY + circle.y,
						toX = this.mouse.clientX + this.window.scrollLeft,
						toY = this.mouse.clientY + this.window.scrollTop,
						dx = toX - fromX,
						dy = toY - fromY,
						d = Math.sqrt(dx ** 2 + dy ** 2),
						force = Math.min(forceStrength / d ** exponent, maxForce),
						forceX = force * dx,
						forceY = force * dy;
					// apply forces
					x += forceX;
					y += forceY;
				}

				// apply spring force towards origin
				x -= springStrength * circle.x;
				y -= springStrength * circle.y;

				// apply inertia and damping
				x += (1 - damping) * (circle.x - circle.lastX);
				y += (1 - damping) * (circle.y - circle.lastY);

				circle.lastX = circle.x;
				circle.lastY = circle.y;
				circle.x = x;
				circle.y = y;
			};

			updateCircle(this.circle1);
			updateCircle(this.circle2);
		},
	},
};
</script>
