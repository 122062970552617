// a promise-returning version of setTimeout
// can be used in async-await functions
// no longer takes a callback, just the number of milliseconds to wait
export const timeout = ms => {
	let handle;
	const promise = new Promise(resolve => (handle = setTimeout(resolve, ms)));
	promise.cancel = () => clearTimeout(handle);
	return promise;
};

// loads an image from a given src string
// returns a promise that resolves when the image loads
// or rejects if there's an error
// useful for preloading images
export const loadImage = src =>
	new Promise((resolve, reject) => {
		const image = new Image();
		image.src = src;
		image.onload = () => resolve(image);
		image.onerror = reject;
	});

export const offsetInParent = (el, parent) => {
	let result = { top: 0, left: 0 };
	while (el && el !== parent) {
		result.top += el.offsetTop;
		result.left += el.offsetLeft;
		el = el.parent;
	}
	return result;
};
import anime from "animejs";
export const sectionAnimation = section => {
	let animation = anime.timeline({
		autoplay: false,
		easing: "linear",
	});
	const totalDuration = (section.offsetHeight / window.innerHeight) * 1000;
	for (let el of section.querySelectorAll(".fade")) {
		const duration = Math.max(100, el.offsetHeight / 2);
		const staggerDuration = totalDuration - duration;

		let { top, left } = offsetInParent(el, section);
		top += el.offsetHeight / 2;
		left += el.offsetWidth / 2;
		top /= window.innerHeight;
		left /= window.innerWidth;

		const delay = Math.min(staggerDuration, 200 + 1000 * top + 300 * (left - 0.5));
		const scale = 1 + 0.1 * (1 - el.offsetWidth / window.innerWidth);
		animation.add(
			{
				targets: el,
				duration,
				opacity: [0, 1],
				scale: [scale, 1],
				delay,
			},
			0
		);
	}
	for (let el of section.querySelectorAll(".reveal")) {
		const duration = Math.max(100, el.offsetHeight / 2);
		const staggerDuration = totalDuration - duration;

		let { top, left } = offsetInParent(el, section);
		top += el.offsetHeight / 2;
		left += el.offsetWidth / 2;
		top /= window.innerHeight;
		left /= window.innerWidth;

		const delay = Math.min(staggerDuration, 200 + 1000 * top + 300 * (left - 0.5));
		const scale = 1 + 0.1 * (1 - el.offsetWidth / window.innerWidth);
		animation.add(
			{
				targets: el,
				duration,
				clipPath: ["inset(0% 0% 50% 0%)", "inset(0% 0% 0% 0%)"],
				translateY: {
					value: ["0%", "80%"],
					duration: 2000,
					easing: "easeInQuad",
				},
				opacity: [0, 1],
				delay,
			},
			0
		);
	}
	for (let el of section.querySelectorAll(".book")) {
		animation.add(
			{
				targets: el,
				duration: totalDuration * 2,
				translateX: ["10%", "-10%"],
				translateY: ["-10%", "10%"],
				opacity: {
					value: [0, 1],
					duration: totalDuration,
				},
				delay: 0,
			},
			0
		);
	}
	return animation;
};
