<template lang="pug">
footer#contact(class="bg-$purple color-white font-size-1.5 p-2 pb-6 !first:mt-4")
	div(class="flexbox-center child:m-0 d:!first:ml-2 font-size-1.2 color-$yellow")
		h2(class="font-size-3 font-weight-900") Get In Touch
	div(class="flexbox-space-around-flex-start m:flex-direction-column m:!first:mt-2")
		div(class="flexbox !first:ml-.5 font-size-1.5")
			a(href="https://www.instagram.com/bringagency/" target="_blank" class="bg-white color-$purple border-radius-999px $w-2.25em w-$w h-$w flexbox-center-center hover:bg-$yellow transition-$transition-hover")
				InstagramIcon
			a(href="https://www.linkedin.com/company/bring-agency/" target="_blank" class="bg-white color-$purple border-radius-999px $w-2.25em w-$w h-$w flexbox-center-center hover:bg-$yellow transition-$transition-hover")
				LinkedInIcon
		p
			| BRING
			| <br><a href="https://goo.gl/maps/UFbEVXQw11vmgXqH7" target="_blank">Level 5, 150 William St</a>
			| <br>Woolloomooloo
			| <br>NSW 2011
		div
			p <MobileIcon class="w-1em vertical-align-middle"/> <a href="tel:+61 2 9207 0696" target="_blank">+61 2 9207 0696</a>
				| <br><EmailIcon class="w-1em vertical-align-middle"/> <a href="mailto:hello@bring.com.au" target="_blank">hello@bring.com.au</a>
			a.button(href="https://au.umusic-online.com/p/5JO2-4S5/bring-mailing-list" target="_blank" class="$color-white $hover-color-$purple mt-1 size-1.2") Subscribe to mailing list
	div(class="d:p-0-4")
		div(class="border-1px-solid-#fff8 blw-0 brw-0 w-100% flexbox-column-center-center !first:mt-3 p-3-0 d:p-3 text-align-center")
			p(class="bold") Any Artist. Any Label. Including the Largest Music Network in the World.
			img(src="~img/footer-icons.png" class="w-100% max-w-60")
	div(class="text-align-center font-size-.8")
		img(src="~img/logo-universal-white.png" class="w-12em mb-.5")
		p &copy; {{year}} BRING. Part of the Universal Music Group. View <a href="https://www.umusic.com.au/privacy-policy/" target="_blank">Privacy Policy</a>
</template>

<style lang="sass" scoped>
p a
	color: var(--yellow)

hr
	opacity: .5
</style>

<script>
import InstagramIcon from "./instagram.svg";
import LinkedInIcon from "./linkedin.svg";
import MobileIcon from "./mobile.svg";
import EmailIcon from "./email.svg";

export default {
	components: {
		InstagramIcon,
		LinkedInIcon,
		MobileIcon,
		EmailIcon,
	},
	computed: {
		year() {
			const metaEl = document.querySelector('meta[name="year"]');
			return metaEl?.content || "2021";
		},
	},
};
</script>
