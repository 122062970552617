<template lang="pug">
#app(class="rel" :style="`--circle-color: ${circleColor}`")
	//- TODO: add circle transition between views when not navigating through the menu
	transition(@leave="onLeave" @enter="onEnter")
		RouterView(:key="$route.path" ref="routerView" @loading="addLoadingPromise" class="router-view" @change-subtitle="pageSubtitle = $event")
	Menu(v-show="showMenu" :expanded.sync="menuExpanded")
	TransitionCircle(ref="circle")
</template>

<script>
import Menu from "@/components/Menu/Menu.vue";
import TransitionCircle from "@/components/TransitionCircle.vue";

import { sample } from "lodash";

const colors = ["yellow", "red", "blue", "green", "purple", "pink"];

export default {
	data() {
		return {
			menuExpanded: false,
			circleColor: "pink",
			showMenu: true,
			pageSubtitle: "",
		};
	},
	created() {
		this.setCircleColor();
	},
	components: {
		Menu,
		TransitionCircle,
	},
	computed: {
		pageTitle() {
			return "BRING | " + (this.pageSubtitle || "Catalysts of Culture");
		},
	},
	methods: {
		scrollBehavior({ to, from, savedPosition }) {
			// this is triggered by the router whenever a scroll should occur due to navigating to a new route
			if (to.hash) {
				return this.scrollTo({ selector: to.hash });
			}
			if (savedPosition) {
				return this.scrollTo(savedPosition);
			}
			return this.scrollTo();
		},
		setCircleColor(color) {
			this.circleColor = color || `var(--${sample(colors)})`;
		},
		async onLeave(el, done) {
			await this.showCircle();
			done();
		},
		async onEnter(el, done) {
			await this.showCircle();
			done();
		},
		async scrollTo({ x = 0, y = 0, selector } = {}) {
			await this.showCircle();
			await this.resolveAllLoadingPromises();
			if (selector) {
				const el = this.$el.querySelector(selector);
				if (el) el.scrollIntoView(true);
				return;
			}
			window.scrollTo(x, y);
		},
		async showCircle() {
			while (!this.$refs.circle) {
				await this.$setTimeout();
			}
			return this.$refs.circle.showCircle();
		},
		async addLoadingPromise(promise) {
			while (!this.$refs.circle) {
				await this.$setTimeout();
			}
			return this.$refs.circle.addLoadingPromise(promise);
		},
		async resolveAllLoadingPromises() {
			while (!this.$refs.circle) {
				await this.$setTimeout();
			}
			return this.$refs.circle.resolveAllLoadingPromises();
		},
		
	},
	watch: {
		$route: {
			handler() {
				if (this.$route.name === "Home") {
					this.pageSubtitle = "";
				} else if (this.$route.name !== "Post") {
					this.pageSubtitle = this.$route.name;
				}
			},
			immediate: true,
		},
		pageTitle: {
			handler(value) {
				document.title = value;
			},
			immediate: true,
		},
	},
};
</script>

<style lang="sass" scoped>
// TODO: change transition to originate from mouse click position
.circle
	will-change: clip-path
	transition: clip-path var(--transition-duration, 800) ease-out
	clip-path: circle(0% at 50% 50%)
	&.expanded
		clip-path: circle(100% at center)
</style>

<style lang="sass">
@import "~normalize.css"
@import "~suitcss-base/lib/base"
@import "~@/assets/css/fonts.css"

html
	--green: #1ba681
	--yellow: #fefe37
	--pink: #fdaaea
	--purple: #75488c
	--red: #fb5254
	--blue: #47d1db

	--grey-light: #d9d9d9

	--z-controls: 10
	--z-menu: 20
	--z-circle: 30

	--transition-hover: background-color 150ms linear, color 150ms linear

	font-family: CircularStd
	letter-spacing: .02em
	background: var(--purple)

body
	overflow-x: hidden

*
	box-sizing: border-box

button
	background: initial
	border: initial
	color: inherit
	padding: 0
	margin: 0
	font-weight: inherit

h1, h2, h3, h4, h5, h6
	font-weight: inherit

a
	text-decoration: initial
	color: inherit
p a
	text-decoration: underline

svg
	width: auto
	height: 1em

img
	max-width: 100%

#app
	height: 100%

button
	cursor: pointer
	&:focus
		outline: none

.button
	display: block
	border: 2px solid var(--color, currentcolor)
	padding: .5em 1.5em
	border-radius: 999px
	cursor: pointer
	transition: var(--transition-hover)
	&:hover, &:focus, &.hover
		background-color: var(--color, black)
		color: var(--hover-color, white)

.section
	padding: 3rem 2rem
	font-weight: 900
	font-size: 1.25rem
	position: relative
	overflow: hidden
	flexbox: column unset center
	text-align: center
	& > h2, & > h3
		font-size: 3rem
	* + *
		margin-top: 2rem
	h2 + h3
		margin-top: 0



.fade-enter-active,
.fade-leave-active
	transition: opacity var(--transition-duration, .3s) linear

.fade-enter,
.fade-leave-to
	opacity: 0

.has-pink-color
	color: var(--pink)
.has-pink-background-color
	background-color: var(--pink)

.has-green-color
	color: var(--green)
.has-green-background-color
	background-color: var(--green)

.has-yellow-color
	color: var(--yellow)
.has-yellow-background-color
	background-color: var(--yellow)

.has-purple-color
	color: var(--purple)
.has-purple-background-color
	background-color: var(--purple)

.has-red-color
	color: var(--red)
.has-red-background-color
	background-color: var(--red)

.has-blue-color
	color: var(--blue)
.has-blue-background-color
	background-color: var(--blue)

.has-small-font-size
	font-size: .75em
.has-regular-font-size
	font-size: 1em
.has-large-font-size
	font-size: 1.5em
.has-xl-font-size
	font-size: 2.5em
.has-2-xl-font-size
	font-size: 3.5em

@omnicss
</style>
