<template>
	<ul class="slider-pagination">
		<li
			v-for="i in total" :key="i" 
			@click="go(i-1)" 
			:class="i === current + 1 && 'active'"
		></li>
	</ul>
</template>

<style scoped>
ul {
	position: absolute;
	bottom: 0;
	left: 0;
	right: 0;
	display: flex;
	justify-content: center;
	list-style: none;
	padding: 0;
	margin: 1rem;
}

li {
	cursor: pointer;
	height: 1.5rem;
	width: 1.5rem;
	border-radius: 999px;
	margin: .25rem;
	border: 2px solid currentcolor;
}

li.active {
	background: currentcolor;
}
</style>

<script>
export default {
	props: {
		total: Number,
		current: Number,
		go: Function,
	}
}
</script>