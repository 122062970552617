<template lang="pug">
section(class="color-$purple bg-white")
	div(class="flexbox-center-center child:m-0 p-2 d:!first:ml-2 font-size-1.2 rel m:justify-content-space-between")
		LogoBring(class="absolute-left-center pl-2 size-2" v-if="$desktop && logo")
		h2(class="font-size-3 font-weight-900") Feed
		SliderPagination(:total="total" :current="current" :go="go")
	Slider(align="center" loop class="font-size-1.5 text-align-center" :current.sync="current" :total.sync="total" ref="feedSlider")
		template(v-slot:default="{slide, current}")
			article.slide(
				v-for="(feedItem,i) in feedItems" 
				v-bind="slide" 
				class="$w-85vmin d:$w-70vmin w-$w color-black flex-shrink-0 font-weight-500 $aspect-(7/6) d:$aspect-(3/4) flexbox-column"
				:style="{...slide.style, '--primary': feedItem.primary_color}"
				v-fade-image
			)
				div(class="color-white min-h-($aspect*$w) rel bg-center/cover bg-color-$primary" :style="feedItem.featured_image && `background-image: url(${feedItem.featured_image})`")
					div(class="absolute-stretch w-100% h-100% bg-$primary opacity-.25")
					h3(class="font-size-1.5em d:font-size-min(1.75em,5vh) flexbox-center-center absolute-stretch m-0 transition-background-.3s p-2 font-weight-900" v-html="feedItem.title")
				div(class="p-3-2 m:p-2-1 !first:mt-2 flex-1 flexbox-column-space-between-center m:justify-content-center")
					component(
						:is="feedItem.external_url ? 'a' : 'router-link'" 
						class="button $color-black $hover-color-white" 
						:to="feedItem.url" 
						:href="feedItem.url" 
						:target="feedItem.external_url ? '_blank' : ''"
					) 
						| Read More
						ExternalLinkIcon(class="vertical-align-top inline-block ml-.75em mr--.5em" v-if="feedItem.external_url")
		template(v-slot:navigation="scope")
			SliderNext(v-bind="scope" class="font-size-3 color-white")
			SliderPrevious(v-bind="scope" class="font-size-3 color-white")
</template>

<style lang="sass" scoped>
.slider-pagination
	absolute: right center
	bottom: auto
	left: auto
	padding: 0
	margin: 0
	margin-right: 1rem
</style>

<script>
import Slider from "@/components/Slider/Slider.vue";
import SliderPagination from "@/components/Slider/SliderPagination.vue";
import SliderNext from "@/components/Slider/SliderNext.vue";
import SliderPrevious from '@/components/Slider/SliderPrevious.vue';

import ExternalLinkIcon from 'svg/external-link.svg'

export default {
	props: {
		logo: Boolean,
	},
	components: {
		Slider,
		SliderPagination,
		SliderNext,
		SliderPrevious,
		ExternalLinkIcon,
	},
	data() {
		return {
			feedItems: [],
			current: 0,
			total: 0,
		};
	},
	async mounted() {
		this.feedItems = await this.$wp.getFeedItems();
	},
	methods: {
		go(...x) {
			return this.$refs.feedSlider.go(...x);
		},
	},
};
</script>
