import {clamp} from 'lodash';

const data = {
	x0: 0,
	x: 0,
	targetX: 0,
	rafHandle: null,
	runningFrameScroll: 0,
	lastScrollTop: document.scrollingElement.scrollTop,
}

window.addEventListener('scroll', onScroll);
window.addEventListener('wheel', onWheel, {passive: false});
queueNextFrame();

function onScroll() {
	const {lastScrollTop, runningFrameScroll} = data;
	const scrollTop = document.scrollingElement.scrollTop;
	const scrollDelta = scrollTop - lastScrollTop;
	
	if (Math.abs(scrollDelta - runningFrameScroll) > 1) {
		data.x0 = data.x = data.targetX = scrollTop;
	}

	data.lastScrollTop = scrollTop;
	data.runningFrameScroll = 0;
}

function onWheel(event) {
	event.preventDefault();
	data.targetX = clamp(data.targetX + event.deltaY, 0, document.scrollingElement.scrollHeight);
	queueNextFrame();
}
const SPRING_CONSTANT = .05;
const DAMPING = .9;
const MAX_ACCELERATION = 5;

function queueNextFrame() {
	data.rafHandle = window.requestAnimationFrame(onFrame);
}

function onFrame() {
	data.rafHandle = null;
	const {x, x0, targetX} = data;
	const vx = x - x0;
	let ax = SPRING_CONSTANT * (targetX - x) || 0;
	ax = clamp(ax, -MAX_ACCELERATION, MAX_ACCELERATION);
	const nextX = x + vx * (1 - DAMPING) + ax;

	if (Math.abs(targetX - nextX) > .5) {
		queueNextFrame();
	}

	data.x0 = x;
	data.x = nextX;
	data.runningFrameScroll += nextX - x;

	document.scrollingElement.scrollTop = data.x;
}