<template>
	<div
		class="slider"
		@wheel="onWheel"
		@mousedown="onMouseDown"
		@mousemove="onMouseMove"
		@mouseup="onMouseUp"
		@touchstart="onTouchStart"
		@touchmove="onTouchMove"
		@touchend="onTouchEnd"
		@dragstart.prevent
	>
		<div class="slides">
			<div class="loop" v-for="loop in loopCount" :key="loop">
				<slot name="default" v-bind="slideSlotScope(loop-1)"></slot>
			</div>
		</div>

		<SliderPagination
			v-if="defaultPagination"
			v-bind="{ total, current, go, progressPercentage }"
		/>
		<slot
			v-else
			name="pagination"
			v-bind="{ total, current, go, progressPercentage }"
		></slot>

		<template v-if="defaultNavigation">
			<SliderNext v-bind="{ next, disabled: nextDisabled }" />
			<SliderPrevious v-bind="{ previous, disabled: previousDisabled }" />
		</template>
		<slot v-else name="navigation" v-bind="{ next, nextDisabled, previous, previousDisabled }"></slot>
	</div>
</template>

<style scoped>
.slider {
	position: relative;
	touch-action: pan-y;
	user-select: none;
}
.slide {
	flex: none;
}
.slides {
	position: relative;
	overflow: hidden;
	width: 100%;
	height: 100%;
	display: flex;
}
.slides ~ * {
	z-index: var(--z-controls, 10);
}
.loop {
	width: 100%;
	display: contents;
	flex: none;
}
</style>

<script>
/*
 * COMPONENTS
 */
import SliderNext from "./SliderNext.vue";
import SliderPrevious from "./SliderPrevious.vue";
import SliderPagination from "./SliderPagination.vue";

/*
 * UTILITIES
 */
import ResizeObserver from 'resize-observer-polyfill';

// TODO: make a version of clamp that can take undefined/null for a bound to ignore that bound
import { clamp, some } from "lodash";

// modulo operator that handles negative numbers, useful for looping an integer as index into an array
const mod = (m, n) => ((m % n) + n) % n;

// smallest difference between two numbers "to" and "from", modulo n
// used for computing the shortest distance and direction between slide indexes when looping is enabled
// modDifference(3, 1, 5) = 2 because mod(1 + 2, 5) == 3
// i.e. if you would like to go to slide 3 from slide 1 when there are 5 slides total, go right 2 slides
// modDifference(4, 1, 5) = -2 because mod(1 - 2, 5) == 4
// i.e. if you would like to go to slide 4 from slide 1 when there are 5 slides total, go left 2 slides
// EDGE CASE: modDifference(3, 1, 4) = 2 (not -2)
const modDifference = (to, from, n) => mod((to - from) + (n - 1) / 2, n) - (n - 1) / 2 

// transform string formatter, takes an object of seperate transform properties and formats them into a CSS transform string
const transform = ({ translateX, translateY, translate, scaleX, scaleY, scale, rotateX, rotateY, rotateZ, rotate }) => {
	let result = [];

	translateX = translateX || translate || 0;
	translateY = translateY || translate || 0;

	// use null checks here as scaleX, scaleY and scale shouldn't default to 1 if they are 0.
	scaleX = scaleX != null ? scaleX : (scale != null ? scale : 1)
	scaleY = scaleY != null ? scaleY : (scale != null ? scale : 1);

	rotateX = rotateX || 0;
	rotateY = rotateY || 0;
	rotateZ = rotateZ || rotate || 0;

	// default units if just a number is passed
	const hasNoUnits = x => x && parseFloat(x).toString() === x.toString();
	if (hasNoUnits(translateX)) {
		translateX += "px";
	}
	if (hasNoUnits(translateY)) {
		translateY += "px";
	}
	if (hasNoUnits(translate)) {
		translate += "px";
	}
	if (hasNoUnits(rotateX)) {
		rotateX += "deg";
	}
	if (hasNoUnits(rotateY)) {
		rotateY += "deg";
	}
	if (hasNoUnits(rotateZ)) {
		rotateZ += "deg";
	}

	// order here matters
	// will likely be refined as I do more examples
	result.push(`translate(${translateX}, ${translateY})`);
	result.push(`rotateX(${rotateX}) rotateY(${rotateY}) rotateZ(${rotateZ})`);
	result.push(`scale(${scaleX}, ${scaleY})`);

	return result.join(" ");
};

/*
 * COMPONENT DEFINITION
 */
export default {
	components: {
		// TODO: SliderNext and SliderPrevious should really be a single component; likely do when adding vertical support
		SliderNext,
		SliderPrevious,
		SliderPagination,
	},
	props: {
		// TODO: add vertical support
		// TODO: should mouse wheel navigation be off by default?
		// TODO: prop to turn off mouse wheel navigation
		// TODO: turn navigation and pagingation into a Boolean prop, should default to false except when the corresponding slot is passed

		/* align: where should the current slide be aligned in the viewport
		 * start and end are included for when vertical support is added.
		 * left === start, center === centre, right === end when in horizontal mode
		 */
		align: {
			type: String,
			validator: x => ["left", "start", "center", "centre", "right", "end"].includes(x),
		},

		/* slidesShown: the number of slides to show in the viewport
		 * sets all slide widths to calculated percentage of the viewport
		 * takes into account gaps between elements by inspecting the margin between slides
		 */
		slidesShown: {
			type: Number,
			validator: x => x > 0,
		},
		
		/* slidesMoved isn't ideal with variable width slides as what you really want is to move one "page" of slides at a time
		 * where a "page" is all the slides that entirely fit on the screen at once
		 * TODO: add "paged" mode to toggle this behaviour
		 */

		// slidesMoved: the number of slides to move when using the navigation buttons or next() / previous()
		slidesMoved: {
			type: Number,
			validator: x => x > 0,
			default: 1,
		},

		// loop: whether or not to loop the slides; otherwise known as infinite mode.
		// TODO: should the loop markup be there when loop is false?
		loop: {
			type: Boolean,
			default: false,
		},

		/* whitespace: whether whitespace should be added to the ends in order to align the end slides as per the align prop
		 * be default, the first slide will be left-aligned and the last slide will be right aligned to the viewport regardless of the align prop.
		 * NOTE: has no effect in loop mode
		 */
		whitespace: {
			type: Boolean,
			default: false,
		},

		/*
		 * scrollable: whether the wheel event can initiate slide changes
		 */
		scrollable: {
			type: Boolean,
			default: false,
		},

		/* addRepeat (advanced): a number of extra times to duplicate the slides when in loop mode.
		 * by default, the slider tries to calculate how many times to loop the slides based on slider width and the total number of slides
		 * if you notice slides popping in and out when sliding, try setting this to 1 (or 2)
		 * should only be necessary when doing custom effects where you are setting slide transforms yourself
		 * BEWARE: setting this to a high number could be very performance-heavy
		 */
		addRepeat: {
			type: Number,
			default: 0,
		},
	},
	data() {
		return {
			// offset coord of the viewport; 0 corresponds to the first slide being left-aligned in the viewport
			viewportOffset: 0,
			// previous viewport offset coord; implies the viewports velocity
			viewportOffsetPrevious: 0,

			// previous viewport index (continuous); see viewportIndex for full description
			viewportIndexPrevious: 0,

			// index of the snap point that the viewport will move towards
			// by default, viewportOffset will spring towards snapOffsets[snapIndex]
			// when in loop mode this doesn't have to be within the bounds of snapOffsets
			// e.g. if there are 5 snap points, viewportIndex is 4 and snapIndex is 2
			// then the viewport should move two slides to the left
			// but if snapIndex was 7, then the viewport should move 3 slides to the right
			snapIndex: 0,

			// array of slide elements; includes looped slides when in loop mode
			slideEls: [],
			// offset coords for each slide such that it is aligned in the viewport
			// TODO: this should probably just be the slide offset coords and the slideSnapOffsets should be a computed property
			slideSnapOffsets: [],

			// whether the viewport has "reached" its snapIndex
			// this happens when the viewport is both moving slow enough and gotten close to the current snap point
			// effects whethers gestures (such as swiping) can currently change the slides
			reachedSnapIndex: false,

			// a wheel event's delta value must be greater than this value to initiate a slide change
			// prevents inertial scrolling from initiating lots of slide changes
			// wheel events increase this value, and it decays back to zero over time
			wheelDeltaThreshold: 0,

			// whether the carousel is currently grabbed (mouse is down or touch is started)
			mouseDown: false,
			// x-coord of the current grab gesture (mouse or touch)
			grabX: 0,
			// previous grab x-coord; implies grab velocity
			// TODO: possibly move to moving average for grab velocity for better swipe detection
			grabXPrevious: 0,

			// the current frame's wheel event delta value; reset to zero after every frame
			wheelDeltaX: 0,

			// viewport width; updated by resizeObserver
			// TODO: this is currently the width of this.$el, should be width of .slides container
			viewportWidth: 0,

			// the current requestAnimationFrame handle
			frameLoopHandle: null,

			// the total number of slides (elements passed into the default slot)
			// NOTE: in loop mode, each slide can have multiple slideEls
			slideCount: 0,
		};
	},
	computed: {
		// TODO: topologically sort this section

		// TODO: simple mode where slides are passed through a non-scoped slot

		// the object that should be bound to each slide by the user 
		slideBinding() {
			let style = {
				transform: transform({translateX: -Math.round(this.viewportOffset)}),		// the viewport transform
			};

			if (this.slidesShown) {
				// fixed-width slide mode
				const gapsTotalWidth = this.slideGap * (this.slidesShown - 1);
				style.width = `calc( (100% - ${gapsTotalWidth}px) / ${this.slidesShown})`;

				// makes all slide gaps consistent
				// TODO: handle variable gap widths
				// TODO: if slideGap becomes a prop, then margins need to also be set in variable width mode
				if (this.slideGap) {
					style.marginLeft = 0;
					style.marginRight = this.slideGap + "px";
				}
			}

			return {
				// TODO: slideEls are currently selected by ".slide", but this could be improved to remove this class binding
				class: "slide",
				style,
			};
		},

		// function to return the object bound to each loop of the default slot
		// takes a 0-indexed loop index and returns the corresponding slot scope binding object
		slideSlotScope() {
			// TODO: clean up and comment these public API variables
			return loopIndex => ({
				slide: this.slideBinding, 
				x: this.viewportOffset,
				clamp,
				transform,
				mod,
				width: this.viewportWidth,
				current: this.current,
				vCurrent: this.snapIndex,
				total: this.slideCount,
				xIndex: this.viewportIndex,
				vTotal: this.slideEls.length,
				slides: slideIndex => {
					const elIndex = loopIndex * this.slideCount + slideIndex;
					return {
						// TODO: rename index to elIndex?
						index: elIndex,
						offset: this.slideSnapOffsets[elIndex],
						width: this.slideEls[elIndex] && this.slideEls[elIndex].offsetWidth,
						dIndex: modDifference(elIndex, this.viewportIndex, this.slideCount),
					};
				}
			});
		},

		// resolves snapIndex to an offset coord in the snapOffsets array
		// treats snapOffsets array like it's infinitely looping on loop mode
		// see description of snapIndex for justification and example
		snapOffset() {
			// TODO: is this ternary even necessary? 
			const loopLength = this.loop ? this.totalSlideLength : 0;
			const loopOffset = loopLength * Math.floor(this.snapIndex / this.slideCount);
			const snapOffsetWrapped = this.snapOffsets[mod(this.snapIndex, this.slideCount)];
			return snapOffsetWrapped + loopOffset;
		},


		viewportIndex() {
			// find where viewportOffset is in relation to the snapOffsets as a fractional index
			// viewportIndex of 1.5 implies that the viewportOffset is halfway between slide index 1 and 2
			// TODO: this should probably be between slideOffsets instead of snapOffsets
			// TODO: this could infinitely loop in a similar fashion to snapOffset instead of clamping;
			// 		 this would prevent clamping of continuous styles that depend on this value when overscrolled
			const upperIndex = this.snapOffsets.findIndex(offset => offset > this.viewportOffset);
			const lowerIndex = upperIndex - 1;
			let viewportIndex;
			if (upperIndex < 0) {
				viewportIndex = this.slideEls.length - 1;
			} else if (lowerIndex < 0) {
				viewportIndex = 0;
			} else {
				// linearly interpolate between the two snapOffsets
				const upperOffset = this.snapOffsets[upperIndex];
				const lowerOffset = this.snapOffsets[lowerIndex];
				viewportIndex = (this.viewportOffset - lowerOffset) / (upperOffset - lowerOffset) + lowerIndex;
			}

			return viewportIndex;
		},

		// TODO: find a way to get rid of this variable
		// the offset of the aligned slide's origin in the viewport
		// a slide is considered aligned with the viewport when viewportOffset equals the slide's snapOffset
		viewportOriginOffset() {
			switch (this.align) {
				case "left":
				case "start":
				default:
					return 0;
				case "center":
				case "centre":
					return this.viewportWidth / 2;
				case "right":
				case "end":
					return this.viewportWidth;
			}
		},

		// TODO: refactor these as a prop instead of an empty slot
		defaultNavigation() {
			return this.$slots.hasOwnProperty("navigation") && this.$slots.navigation == undefined;
		},
		defaultPagination() {
			return this.$slots.hasOwnProperty("pagination") && this.$slots.pagination == undefined;
		},
		nextDisabled() {
			return !this.loop && this.snapIndex === this.snapCount - 1;
		},
		previousDisabled() {
			return !this.loop && this.snapIndex === 0;
		},
		progressPercentage() {
			const maxSlideOffset = this.snapOffsets[this.snapOffsets.length - 1];
			return clamp(this.viewportOffset / maxSlideOffset, 0, 1) * 100;
		},
		slideGap() {
			if (!this.slideEls || this.slideSnapOffsets.length < 2) return;
			const firstSlideStyles = this.slideEls[0] && window.getComputedStyle(this.slideEls[0]);
			const secondSlideStyles = this.slideEls[1] && window.getComputedStyle(this.slideEls[1]);
			if (!firstSlideStyles || !secondSlideStyles) return;
			return parseInt(firstSlideStyles.marginRight) + parseInt(secondSlideStyles.marginLeft);
		},
		totalSlideLength() {
			let result;
			if (this.loop) {
				result = this.slideSnapOffsets[this.slideCount] - this.slideSnapOffsets[0];
			} else {
				result =
					this.slideSnapOffsets[this.slideCount - 1] -
					this.slideSnapOffsets[0] +
					(this.slideEls[this.slideCount - 1] && this.slideEls[this.slideCount - 1].offsetWidth);
			}
			return !isNaN(result) && result;
		},
		lowerXLimit() {
			return 1;
		},
		upperXLimit() {
			return this.lowerXLimit && this.lowerXLimit + this.totalSlideLength;
		},
		loopCount() {
			if (!this.loop) return 1;
			if (!this.totalSlideLength || !this.viewportWidth) return 2;
			return Math.ceil(this.viewportWidth / this.totalSlideLength) + 1 + this.addRepeat;
		},
		snapOffsets() {
			// the actual snap points for the slider
			// similar to slideSnapOffsets except when whitespace = false
			// i.e. remove the last few slides offsets
			if (this.whitespace || this.loop || !this.totalSlideLength || !this.viewportWidth) return this.slideSnapOffsets;
			const minSlideOffset = this.viewportOriginOffset;
			const maxSlideOffset = this.totalSlideLength - this.viewportWidth + this.viewportOriginOffset;
			let result = this.slideSnapOffsets;

			const maxSliceIndex = this.slideSnapOffsets.findIndex(x => x > maxSlideOffset);
			if (maxSliceIndex >= 0) {
				result = result.slice(0, maxSliceIndex);
				if (Math.abs(result[result.length - 1] - maxSlideOffset) >= 1) result.push(maxSlideOffset);
			}

			const minSliceIndex = this.slideSnapOffsets.findIndex(x => x > minSlideOffset);
			if (minSliceIndex >= 1) {
				result = result.slice(minSliceIndex);
				if (Math.abs(result[0] - minSlideOffset) >= 1) result.unshift(minSlideOffset);
			}
			return result;
		},
		snapCount() {
			return this.snapOffsets.length;
		},
		current() {
			return mod(this.snapIndex, this.slideCount);
		},
		total() {
			return this.loop ? this.slideCount : this.snapCount
		}
	},
	mounted() {
		this.updateSlideEls();
		this.calculateSlideSnapOffsets();
		this.startFrameLoop();
		this.goToCurrentSlideInstantly();
		this.startResizeObserver();
		this.startMutationObserver();
	},
	destroyed() {
		this.stopFrameLoop();
	},
	watch: {
		snapIndex() {
			this.reachedSnapIndex = false;
		},
		align() {
			this.goToCurrentSlideInstantly();
		},
		viewportWidth() {
			this.calculateSlideSnapOffsets();
			this.goToCurrentSlideInstantly();
		},
		slideEls() {
			this.calculateSlideSnapOffsets();
		},
		async slidesShown() {
			await this.$nextTick();
			this.calculateSlideSnapOffsets();
			this.goToCurrentSlideInstantly();
		},
		current(value) {
			this.$emit('update:current', value);
		},
		total(value) {
			this.$emit('update:total', value);
		},
		viewportIndex(value) {
			this.$emit('update:viewportIndex', value);
		}
	},
	methods: {
		onFrame() {
			this.startFrameLoop();
			
			const SPRING_CONSTANT = 0.1;
			const DAMPING = 0.6;
			const WHEEL_DELTA_THRESHOLD_DECAY = 0.7;
			const MIN_WHEEL_DELTA_THRESHOLD = 4;
			const MAX_ACCELERATION = 30;
			const OFFSET_TOLERANCE = 0.5;

			if (isNaN(this.viewportOffset)) {
				this.viewportOffset = 0;
			}

			// pull out variables from this
			let x = this.viewportOffset;
			const { viewportOffsetPrevious, grabX, grabXPrevious, wheelDeltaX, wheelDeltaThreshold, snapOffset } = this;

			// compute deltas
			const vGrabX = grabX - grabXPrevious;
			const vx = x - this.viewportOffsetPrevious;

			// update last variables
			this.viewportOffsetPrevious = x;
			this.grabXPrevious = grabX;
			this.viewportIndexPrevious = this.viewportIndex;
			this.wheelDeltaThreshold = Math.max(
				wheelDeltaThreshold * WHEEL_DELTA_THRESHOLD_DECAY + Math.abs(wheelDeltaX),
				MIN_WHEEL_DELTA_THRESHOLD
			);
			this.wheelDeltaX = 0;

			// update x
			if (this.mouseDown) {
				x -= vGrabX;
			} else {
				let ax = SPRING_CONSTANT * (snapOffset - x) || 0;
				ax = clamp(ax, -MAX_ACCELERATION, MAX_ACCELERATION);
				x = x + vx * (1 - DAMPING) + ax;
			}
			if (this.reachedSnapIndex) {
				x += wheelDeltaX;
			}
			if (Math.abs(snapOffset - x) < OFFSET_TOLERANCE) {
				x = snapOffset;
			}
			if (this.loop) {
				if (x < this.lowerXLimit) {
					x += this.totalSlideLength;
					this.viewportOffsetPrevious += this.totalSlideLength;
					this.snapIndex += this.reachedSnapIndex && this.mouseDown ? this.slideCount - 1 : this.slideCount;
					this.reachedSnapIndex = false
				} else if (x > this.upperXLimit) {
					x -= this.totalSlideLength;
					this.viewportOffsetPrevious -= this.totalSlideLength;
					this.snapIndex -= this.reachedSnapIndex && this.mouseDown ? this.slideCount - 1 : this.slideCount;
					this.reachedSnapIndex = false
				}
			}
			this.viewportOffset = x;

			const { viewportIndex, viewportIndexPrevious, snapIndex } = this;
			const vXIndex = viewportIndex - viewportIndexPrevious;

			if (!this.reachedSnapIndex) {
				this.reachedSnapIndex = Math.abs(snapIndex - viewportIndex) < 0.1 && Math.abs(vXIndex) < 0.025;
			}

			if (this.reachedSnapIndex) {
				if (viewportIndex - snapIndex > 0.25) {
					this.next();
				} else if (viewportIndex - snapIndex < -0.25) {
					this.previous();
				}

				const inputDeltaX = wheelDeltaX + (!this.mouseDown ? -vGrabX : 0);
				if (inputDeltaX > wheelDeltaThreshold) {
					this.next();
				} else if (inputDeltaX < -wheelDeltaThreshold) {
					this.previous();
				}
			}
		},
		startFrameLoop() {
			this.frameLoopHandle = window.requestAnimationFrame(this.onFrame);
		},
		stopFrameLoop() {
			window.cancelAnimationFrame(this.frameLoopHandle);
		},
		startMutationObserver() {
			const mutationObserver = new MutationObserver(() => {
				this.updateSlideEls();
			});
			mutationObserver.observe(this.$el, { childList: true, subtree: true });
		},
		startResizeObserver() {
			const resizeObserver = new ResizeObserver(entries => {
				const newWidth = entries[0].contentRect.width;
				this.viewportWidth = newWidth;
			});
			this.$el && resizeObserver.observe(this.$el);
		},
		updateViewportWidth() {
			this.viewportWidth = this.$el.getBoundingClientRect().width;
		},
		calculateSlideSnapOffsets() {
			const getSlideOffset = el => {
				switch (this.align) {
					case "left":
					case "start":
					default:
						return el.offsetLeft - this.viewportOriginOffset;
					case "center":
					case "centre":
						return el.offsetLeft + 0.5 * el.offsetWidth - this.viewportOriginOffset;
					case "right":
					case "end":
						return el.offsetLeft + el.offsetWidth - this.viewportOriginOffset;
				}
			};
			this.slideSnapOffsets = this.slideEls.map(getSlideOffset);
		},
		goToCurrentSlideInstantly() {
			this.viewportOffset = this.snapOffset;
			this.viewportOffsetPrevious = this.snapOffset;
		},
		updateSlideEls() {
			if (!this.$el || !this.$el.querySelectorAll) return;
			this.slideEls = Array.from(this.$el.querySelectorAll(".slide"));
			this.slideCount = this.slideEls.length / this.loopCount;
		},
		onWheel(event) {
			if (this.scrollable) {
				event.preventDefault();
				this.wheelDeltaX += event.deltaX + event.deltaY;
			}
		},
		onMouseDown(event) {
			this.mouseDown = true;
			this.updateMouseFromMouseEvent(event);
			this.grabXPrevious = this.grabX;
		},
		// TODO: listeners for mousemove, mouseup, touchmove, and touchend should be on the window.
		onMouseMove(event) {
			this.mouseDown && this.updateMouseFromMouseEvent(event);
		},
		onMouseUp(event) {
			this.mouseDown = false;
			this.updateMouseFromMouseEvent(event);
		},
		onTouchStart(event) {
			if (event.target === this.$el || some(this.slideEls, x => x.contains(event.target))) {
				this.mouseDown = true;
				this.updateMouseFromTouchEvent(event);
				this.grabXPrevious = this.grabX;
			}
		},
		onTouchMove(event) {
			this.mouseDown && this.updateMouseFromTouchEvent(event);
		},
		onTouchEnd(event) {
			this.mouseDown = false;
			this.updateMouseFromTouchEvent(event);
		},
		updateMouseFromMouseEvent(event) {
			this.grabX = event.clientX;
		},
		updateMouseFromTouchEvent(event) {
			if (event.touches[0]) {
				this.grabX = event.touches[0].clientX;
			}
		},
		next() {
			this.jump(this.slidesMoved);
		},
		previous() {
			this.jump(-this.slidesMoved);
		},
		jump(numberOfSlides) {
			if (this.loop) {
				this.snapIndex += numberOfSlides;
			} else {
				this.snapIndex = clamp(this.snapIndex + numberOfSlides, 0, this.snapCount - 1);
			}
		},
		go(index) {
			this.snapIndex += modDifference(index, this.snapIndex, this.slideCount);
		},
		log: console.log,
	},
};
</script>
