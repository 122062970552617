import Vue from "vue";
import App from "./App.vue";
import router from "./router";

Vue.config.productionTip = false;

import VFooter from "@/components/Footer/VFooter.vue";
Vue.component("VFooter", VFooter);
import LogoBring from "@/components/LogoBring.vue";
Vue.component("LogoBring", LogoBring);

import breakpoints from "./plugins/breakpoints.js";
Vue.use(breakpoints);

import wordpress from "./plugins/wordpress.js";
Vue.use(wordpress, {
	endpoint: process.env.NODE_ENV === "production" ? "/wp-json" : "https://bring-au.umg-wp-stage.com/wp-json",
});

import fadeImage from './plugins/fadeImage.js';
Vue.use(fadeImage);

import timeouts from './plugins/timeouts.js';
Vue.use(timeouts);

import '@/plugins/smoothMouseWheel.js';

new Vue({ ...App, router, el: "#app" });
