<template lang="pug">
.transition-circle(
	class="fixed t-0 l-0 r-0 b-0 bg-$circle-color z-index-$z-circle"
	:style="`--transition-duration: ${transitionDuration}ms`"
	:class="{expanded}" 
)
</template>

<style lang="sass" scoped>
.transition-circle
	will-change: clip-path
	transition: clip-path var(--transition-duration, 800) ease-out
	clip-path: circle(0% at 50% 50%)
	&.expanded
		clip-path: circle(100% at center)
</style>

<script>
export default {
	data() {
		return {
			loadingPromises: [],
			showCirclePromise: null,
			transitionDuration: 800, // in ms
		};
	},
	computed: {
		expanded() {
			return this.showCirclePromise || this.loadingPromises.length;
		},
	},
	created() {
		this.showCircle();
	},
	methods: {
		async showCircle() {
			const circleEl = this.$el;
			if (this.showCirclePromise) return this.showCirclePromise;
			this.showCirclePromise = circleEl
				? Promise.race([
						new Promise(resolve => circleEl.addEventListener("transitionend", resolve, { once: true })),
						this.$setTimeout(this.transitionDuration),
				  ])
				: this.$setTimeout();
			return this.showCirclePromise.then(() => this.showCirclePromise = null)
		},
		async addLoadingPromise(promise) {
			this.showCircle();
			if (promise && (typeof promise.then === 'function')) {
				this.loadingPromises.push(promise);
				await promise;
				this.removeTransitionPromise(promise);
			}
		},
		removeTransitionPromise(promise) {
			const index = this.loadingPromises.findIndex(x => x === promise);
			if (index < 0) return;
			this.loadingPromises.splice(index, 1);
		},
		async resolveAllLoadingPromises() {
			return Promise.all(this.loadingPromises);
		}
	},
};
</script>
