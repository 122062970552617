<template lang="pug">
div(class="$gutter-4rem bg-white m:$gutter-1rem" :style="`--primary: ${post.primary_color}`")
	div(class="flexbox-unset-center !first:ml-1 font-size-1.5 p-2-$gutter-1 m:p-$gutter")
		LogoBring
		CrossIcon(v-if="post.logo_dark")
		img(v-if="post.logo_dark" :src="post.logo_dark" class="max-w-3em ml-5em max-h-3em")
	div(v-if="post.featured_image" class="bg-$primary-no-repeat-content-box-center/cover w-100% p-0-$gutter max-h-75vh" style="height: calc(9 / 16 * (100vw - 2 * var(--gutter)));" :style="{backgroundImage: `url('${post.featured_image}')`}")
	article(class="p-2em-$gutter-6em wp-content")
		h1(v-html="post.title")
		time(:datetime="post.date" v-if="category !== 'case-study'") {{post.date_formatted}}
		div(v-html="post.content" class="wp-content")
	GridOurWork(:exclude-slugs="[post.slug]" v-if="category === 'case-study'" :row-limit="1")
	GridFeed(:exclude-slugs="[post.slug]" v-else :row-limit="1")
	VFooter
</template>

<style lang="sass">
.wp-content
	flexbox: column

	// spacing
	*
		margin: 0
	* + *
		margin-bottom: 0
		margin-top: 3rem
		@breakpoint mobile
			margin-top: 2rem
	p + p,
	h3 + *
		margin-bottom: 0
		margin-top: 1rem
	h1 + h2,
	.has-2-xl-font-size + .has-xl-font-size
		margin-top: 0
	h3 + h4,
	h4 + h5,
	h5 + h6,
	h2 + time,
	ul * + *
		margin-top: .5em
	h1, h2, h3, h4, h5, h6
		& + .wp-block-columns
			margin-top: 0

	// text styles
	font-weight: 400
	letter-spacing: -.02em
	line-height: 1.2
	p
		line-height: 1.4
	@breakpoint desktop
		font-size: 1.5rem
	h1, blockquote, .has-primary-color
		color: var(--primary)
	h1
		font-size: 3.5em
		font-weight: 900
	h2
		font-size: 1.5em
		font-weight: 900
	h3, h4
		font-size: 1em
		font-weight: 700
	blockquote
		font-size: 1.75em
		font-weight: 600

	blockquote
		position: relative
		padding: 0 2em
		&::before, &::after
			position: absolute
			top: 50%
			font-size: 3em
			transform: translateY(-35%)
		&::before
			content: "\201C"
			left: 0
		&::after
			content: "\201D"
			right: 0
	time
		color: var(--grey-light)
	figcaption, blockquote cite
		color: var(--grey-light)
		font-size: 1rem
		margin-top: 1rem
	strong
		font-weight: 900
	.is-style-rounded img
		clip-path: circle(50% at center)
	ul
		list-style: disc
		ul
			list-style: circle

	ul, ol
		ul, ol
			padding-left: 1em
			padding-top: .5em

	// gutters
	blockquote p
		padding: 0
	.alignfull, .alignwide
		padding: 0
		img
			width: 100%
	.alignfull
		margin-left: calc(-1 * var(--gutter))
		margin-right: calc(-1 * var(--gutter))

	// center aligned elements
	h1, blockquote, dl, time
		max-width: 20em
	h1, blockquote, dl, time, figcaption, .has-text-align-center, .aligncenter
		align-self: center
		text-align: center
	img, video, iframe
		max-width: 100%
		align-self: center
		height: auto


	.has-text-align-right, .alignright
		align-self: flex-end
		text-align: right

.wp-block-columns
	flexbox: column
	--spacing: 2rem
	@breakpoint mobile
		--spacing: 1rem
		margin-top: calc(2rem - .5 * var(--spacing))
		margin-bottom: calc(-.5 * var(--spacing))
	@breakpoint desktop
		flexbox: row
		margin-left: calc(-.5 * var(--spacing))
		margin-right: calc(-.5 * var(--spacing))

	& > * + *
		margin-top: 0

	h1, h2, h3, h4, h5, h6, p, figure, ul
		padding: 0

.wp-block-column
	flexbox: column
	flex: 1 0
	padding-top: calc(.5 * var(--spacing))
	padding-bottom: calc(.5 * var(--spacing))
	@breakpoint desktop
		padding-left: calc(.5 * var(--spacing))
		padding-right: calc(.5 * var(--spacing))
	&.is-vertically-aligned-center
		justify-content: center

.wp-block-image
	flexbox: column

.wp-block-embed
	&__wrapper
		position: relative
		padding-bottom: 56.25%
		iframe 
			position:  absolute
			top:  0
			left:  0
			right:  0
			bottom:  0
			width:  100%
			height: 100%

.wp-block-button
	flexbox: center center
	--background-color: var(--primary)
	--color: white
	color: var(--color)
	&__link
		display: block
		border: 2px solid var(--background-color, black)
		padding: .5em 1.5em
		border-radius: 999px
		cursor: pointer
		transition: var(--transition-hover)
		background-color: var(--background-color, black)
		&:focus, &:hover
			color: var(--background-color, black)
			background-color: var(--color, white)
	&.is-style-outline &__link
		color: var(--background-color, black)
		background-color: var(--color, white)
		&:focus, &:hover
			color: var(--color, white)
			background-color: var(--background-color, black)

</style>

<script>
import GridOurWork from "@/components/GridOurWork.vue";
import GridFeed from '@/components/GridFeed.vue';

import CrossIcon from "svg/cross.svg";

import { preparePost } from "@/plugins/wordpress.js";

export default {
	components: {
		GridOurWork,
		GridFeed,
		CrossIcon,
	},
	props: {
		slug: String,
		category: String,
	},
	data() {
		return {
			post: {},
		};
	},
	async created() {
		this.$emit(
			"loading",
			this.getPost()
		);
	},
	watch: {
		post(post) {
			if (post?.title) {
				this.$emit('change-subtitle', post.title);
			}
		}
	},
	methods: {
		async getPost() {
			try {
				const categories = await this.$wp.getCategories();
				const categoryId = categories[this.category]?.id;
				this.post = await this.$wp.$request
					.posts()
					.categories(categoryId)
					.slug(this.slug)
					.embed()
					.then(x => x[0])
					.then(preparePost)
			} catch(err) {
				console.error(err);
				this.$router.replace({name: "Home"});
			}
		}
	}
};
</script>
