<template lang="pug">
section#talent(class="section" v-scroll-progress="{scrollTop: window.scrollTop, innerHeight: $innerHeight, animation: talentAnimation}" ref="talent")
	h3.fade {{ home ? 'Any Talent. Any Label.' : 'Talent' }}
	p.fade(class="d:max-w-50%") {{ subtitle }}
	ul.list-artists
		li.reveal(v-for="{src, name} in artistImages" class="rel")
			transition(name="fade")
				div(class="absolute-stretch h-100% w-100% important:m-0" :key="src")
					img(class="absolute-stretch h-100% w-100% object-fit-cover object-position-bottom-center" :src="src" v-fade-image)
					h4(class="absolute-bottom-stretch color-white p-1" v-html="name + '.'")
	p.fade(class="size-2" v-if="!home") Plus thousands more...
</template>

<script>
import { clamp, shuffle } from "lodash";

const NUMBER_OF_ARTISTS = 9;

import { scrollMixin } from "@/components/SectionDuotone.vue";
import { sectionAnimation } from "@/plugins/_utils.js";

export default {
	mixins: [scrollMixin],
	props: {
		home: Boolean,
	},
	data() {
		return {
			allArtistImages: [],
			artistImages: Array.from(new Array(NUMBER_OF_ARTISTS), () => ({})),
			talentAnimation: null,
		};
	},
	computed: {
		subtitle() {
			return this.home
				? "We pride ourselves on finding the right talent for brands, including..."
				: "We work with the biggest talent on the planet as well as the biggest names in music tomorrow. We pride ourselves in finding the right talent for brands from any label including EMI, Island, Republic, Def Jam and many more.";
		},
	},
	created() {
		this.getArtistImages();
	},
	mounted() {
		this.createAnimations();
	},
	watch: {
		allArtistImages() {
			this.randomiseArtistImages();
			this.$setInterval(() => {
				this.randomiseArtistImages(3);
			}, 5000);
		},
		$innerHeight: "createAnimations",
		$innerWidth: "createAnimations",
	},
	methods: {
		randomiseArtistImages(number = NUMBER_OF_ARTISTS) {
			const allArtistImages = this.allArtistImages;
			if (!allArtistImages.length) return;
			const oldImages = this.artistImages;
			const newLength = Math.max(number, oldImages.length);

			let newImages = shuffle(allArtistImages)
				.filter(x => !oldImages.includes(x))
				.slice(0, number);
			newImages.length = newLength;
			newImages = shuffle(newImages);
			for (let i = 0; i < newImages.length; i++) {
				newImages[i] = newImages[i] || oldImages[i] || {};
			}
			this.artistImages = newImages;
		},
		async getArtistImages() {
			this.allArtistImages = await this.$wp.getPage("what-we-do").then(
				page =>
					page.acf?.artist_images.map(x => ({
						src: x.url,
						name: x.title,
					})) || []
			);
		},
		async createAnimations() {
			this.talentAnimation = sectionAnimation(this.$refs.talent);
		},
	},
};
</script>

<style lang="sass" scoped>
.list-artists
	display: grid
	width: 100%
	margin-top: 4rem
	grid-gap: 1rem
	grid-template-columns: repeat(2, 1fr)
	li
		padding-bottom: 100%
		position: relative
		margin-top: 0
		text-align: left
	@breakpoint not-small
		li:nth-child(even)
			top: 50%
	@breakpoint small
		grid-template-columns: repeat(5, 1fr)
		grid-gap: 60% 0
		padding-bottom: 60%
		li
			width: 120%
			padding-bottom: 120%
			&:nth-child(3n+2)
				justify-self: center
			&:nth-child(3n+3)
				justify-self: end
			&:nth-child(6n+1)
				background: var(--green)
			&:nth-child(6n+2)
				background: var(--pink)
			&:nth-child(6n+3)
				background: var(--purple)
			&:nth-child(6n+4)
				background: var(--blue)
			&:nth-child(6n+5)
				background: var(--yellow)
			&:nth-child(6n+6)
				background: var(--purple)
			&:nth-child(1)
				left: 0
				top: 0
				z-index: 1
			&:nth-child(2)
				top: 140%
				left: 0%
				z-index: 1
			&:nth-child(3)
				top: 20%
				left: 0
				text-align: right
			&:nth-child(4)
				top: -10%
				left: 15%
			&:nth-child(5)
				top: 110%
				left: -10%
				text-align: right
			&:nth-child(6)
				top: 65%
				left: 25%
			&:nth-child(7)
				top: 80%
				left: 55%
				z-index: 1
			&:nth-child(8)
				top: 75%
				left: 130%
			&:nth-child(9)
				top: -50%
				left: -35%
</style>
