<template lang="pug">
router-link(v-if="$route.name !== 'Home'" :to="{name: 'Home'}" class="logo-bring")
	LogoBringIcon
LogoBringIcon(class="logo-bring" v-else)
</template>

<style lang="sass" scoped>
.logo-bring
	height: auto
	width: 3em
	line-height: 1
	@breakpoint desktop
		width: auto
		height: 1em
</style>

<script>
import LogoBringIcon from 'svg/logo-bring.svg?inline';

export default {
	components: {
		LogoBringIcon,
	}
}
</script>